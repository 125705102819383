<avn-detail-layout>
  <avn-detail-header (closed)="onClose()" [template]="detailHeaderTemp"></avn-detail-header>
  <ng-template #detailHeaderTemp>
    <div class="detail-header">
      <span>{{ tag.key }}</span>
      <avn-badge *ngIf="propertyType !== 'asset'" [label]="tag['level']" [badgeSize]="IBadgeSize.EXTRA_SMALL"></avn-badge>
    </div>
  </ng-template>
  <avn-detail-content avnScroll>
    <form class="tag-detail-body-wrapper" [formGroup]="tagForm">
      <div class="tag-detail-type-dropdown">
        <ng-container *ngIf="detectedType === 'schema'; else selectTemplate">
          <div class="json-schema-type">
            <span>{{ 'tagOperation.TAG_OPERATION.TAG_DETAIL.TYPE_20' | transloco }}</span>
            <span>{{ 'tagOperation.TAG_OPERATION.TAG_TYPES.SCHEMA_NAME_20' | transloco }}</span>
          </div>
        </ng-container>
        <ng-template #selectTemplate>
          <amp-tag-type-selector [schemaTypeOptions]="schemaTypeOptions" [selectedType]="detectedType" (selectedTypeChanged)="onSchemaTypeChanged($event)"> </amp-tag-type-selector>
        </ng-template>
        <div>
          <avn-switch
            *ngIf="typeAsConstraint || detectedType | applyFunction: ableToAssignMultiValue"
            labelPosition="after"
            formControlName="multivalues"
            class="tag-detail-switch-multiple-values"
            [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.ALLOW_MULTIPLE_VALUES_60' | transloco"
            [status]="tag.description.multivalues"
            [avnTheme]="ThemeType.PRIMARY"
          ></avn-switch>
        </div>
      </div>
      <div class="dynamic" *ngIf="typeAsConstraint || detectedType | applyFunction: ableToAssignDynamicValue">
        <avn-switch labelPosition="after" formControlName="dynamic" [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.DYNAMIC_20' | transloco" [avnTheme]="ThemeType.PRIMARY"></avn-switch>
        <avn-icon [icon]="'action:info'" [fontSize]="18" [avnTooltip]="'tagOperation.TAG_OPERATION.TAG_DETAIL.DYNAMIC_TOOLTIP_200' | transloco"></avn-icon>
      </div>

      <div class="tag-detail-tabs">
        <div class="section-title">{{ 'tagOperation.TAG_OPERATION.TAG_DETAIL.CONSTRAINTS_40' | transloco }}</div>
        <ng-container *ngIf="!typeAsConstraint && !detectedType">
          <avn-empty-data-message
            class="empty-constraint"
            icon="action:info_outline"
            [text]="'tagOperation.TAG_OPERATION.TAG_DETAIL.EMPTY_CONSTRAINT_TEXT_40' | transloco"
            [subText]="'tagOperation.TAG_OPERATION.TAG_DETAIL.EMPTY_CONSTRAINT_SUBTEXT_100' | transloco"
          ></avn-empty-data-message>
        </ng-container>
        <avn-json-schema-constraint
          [pristine]="tagForm.pristine"
          [type]="typeAsConstraint"
          [schema]="schemaAsConstraint"
          (typeChanged)="onConstraintNotifyType($event)"
          (enumChanged)="onEnumChanged($event)"
          (changed)="onSchemaEdited($event)"
        ></avn-json-schema-constraint>
        <div class="section-title">{{ 'tagOperation.TAG_OPERATION.TAG_DETAIL.DESCRIPTION_40' | transloco }}</div>
        <avn-json-schema-description [schemaFormGroup]="tagForm.get('schema')" [jsonSchema]="schemaAsConstraint" [hideDefault]="tagForm.controls.multivalues.value"></avn-json-schema-description>
      </div>
    </form>
  </avn-detail-content>

  <avn-detail-footer>
    <ng-container *ngIf="detrimentalValues$ | async as detrimentalValues">
      <ng-container *ngIf="assetWarningMessage$ | async as tagOperations">
        <!-- Show errors when user performs more than 1 operations on one tag value -->
        <amp-invalid-enum-tag-operation-message *ngIf="detrimentalValues.length > 0" [tags]="detrimentalValues"> </amp-invalid-enum-tag-operation-message>

        <!-- Show summary of affected entities only when there is no error -->
        <amp-invalid-tag-message-summary *ngIf="detrimentalValues.length === 0 && tagOperations.length > 0 && tagForm.dirty" [tagOperations]="tagOperations" [propertyType]="propertyType">
        </amp-invalid-tag-message-summary>
      </ng-container>
    </ng-container>

    <div class="warning" *ngIf="externalOwner && tagForm.dirty">
      <avn-icon [fontSize]="27" [avnTheme]="'warning'" [icon]="'communication:sms_failed'"></avn-icon>
      <h5>{{ 'tagOperation.TAG_OPERATION.TAG_DETAIL.WARNING_200' | transloco: { owner: externalOwner } }}</h5>
    </div>

    <div class="buttons">
      <avn-button [label]="'button.close' | transloco" (click)="onClose()"></avn-button>
      <avn-button
        [disabled]="tagForm.get('schema').pristine || !constraintValid || !tagForm.valid || !tagForm.dirty || (isValidatingTag$ | async) || (detrimentalValues$ | async).length > 0"
        [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.ACTIONS.APPLY_30' | transloco"
        [avnTheme]="ThemeType.PRIMARY"
        (actioned)="applyChanges()"
      ></avn-button>
    </div>
  </avn-detail-footer>
</avn-detail-layout>
