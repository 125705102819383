import {
  AutocompleteModule,
  BadgeModule,
  ButtonModule,
  CalendarModule,
  CoreModule,
  DataTableModule,
  EmptyDataMessageModule,
  FormFieldModule,
  IconModule,
  InputDateTimePickerModule,
  InputModule,
  ModalModule,
  NlpExpressionInputModule,
  ProgressbarModule,
  SelectModule,
  SliderModule,
  SwitchModule,
  TimePickerModule,
  TooltipModule,
} from '@activia/ngx-components';
import { UIModule } from '@amp/ui';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';

import { NlpDatePickerComponent } from './nlp/nlp-date-picker/nlp-date-picker.component';
import { NlpManagerPickerComponent } from './nlp/nlp-manager-picker/nlp-manager-picker.component';
import { DeviceAlarmComponent } from './components/device-alarm/device-alarm.component';
import { DeviceFieldTemplatesComponent } from './components/device-field-templates/device-field-templates.component';
import { ChartCoreModule, PercentDonutModule } from '@activia/dataviz';
import { SearchDevicesComponent } from './components/search-devices/search-devices.component';
import { FeatureToggleModule } from '@amp/feature-toggle';
import { SearchDevicesFormComponent } from './components/search-devices-form/search-devices-form.component';
import { SharedDevicesComponent } from './components/shared-devices/shared-devices.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { devicesReducer, initialDevicesState } from './store/devices.reducer';
import { DevicesEffects } from './store/devices.effects';
import { NlpNumericRangePickerComponent } from './nlp/nlp-numeric-range-picker/nlp-numeric-range-picker.component';
import { NlpNumericValuePickerComponent } from './nlp/nlp-numeric-value-picker/nlp-numeric-value-picker.component';
import { ExportDevicesTaskPanelItemComponent } from './components/export-devices-task-panel-item/export-devices-task-panel-item.component';
import { ExportDevicesTasksSummaryModalComponent } from './components/export-devices-tasks-summary-modal/export-devices-tasks-summary-modal.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    RouterModule,
    StoreModule.forFeature('devices', devicesReducer, { initialState: initialDevicesState }),
    EffectsModule.forFeature([DevicesEffects]),
    TooltipModule,
    TranslocoModule,
    BadgeModule,
    FormsModule,
    ReactiveFormsModule,
    SelectModule,
    InputModule,
    FormFieldModule,
    SliderModule,
    InputDateTimePickerModule,
    CalendarModule,
    TimePickerModule,
    IconModule,
    UIModule,
    AutocompleteModule,
    ChartCoreModule,
    PercentDonutModule,
    FeatureToggleModule,
    NlpExpressionInputModule,
    DataTableModule,
    EmptyDataMessageModule,
    ButtonModule,
    SwitchModule,
    ModalModule,
    ProgressbarModule,
  ],
  declarations: [
    NlpDatePickerComponent,
    NlpNumericRangePickerComponent,
    NlpManagerPickerComponent,
    DeviceAlarmComponent,
    DeviceFieldTemplatesComponent,
    SearchDevicesComponent,
    SearchDevicesFormComponent,
    SharedDevicesComponent,
    NlpNumericValuePickerComponent,
    ExportDevicesTaskPanelItemComponent,
    ExportDevicesTasksSummaryModalComponent,
  ],
  exports: [
    NlpDatePickerComponent,
    NlpNumericRangePickerComponent,
    NlpManagerPickerComponent,
    DeviceAlarmComponent,
    DeviceFieldTemplatesComponent,
    SearchDevicesComponent,
    SearchDevicesFormComponent,
    SharedDevicesComponent,
    ExportDevicesTaskPanelItemComponent,
    ExportDevicesTasksSummaryModalComponent,
  ],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: 'alarm-events', multi: true }],
})
export class DevicesModule {}
