import { TagKeyDescDTO } from '@activia/cm-api';
import { buildJSONSchemaRegex, ConstraintTypeEnum, getJSONSchemaType, IGroupProperties, IJsonSchema, IJsonSchemaResult, IProperties, JsonSchemaFormFactoryService } from '@activia/json-schema-forms';
import { IOptionData } from '@activia/ngx-components';
import { ConstraintTypes, ITagChangeSummary } from '@amp/tag-operation';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest, filter, first, map, Observable, of, skip, Subject, switchMap, takeUntil } from 'rxjs';
import { BoardOrgpathStore, IOrgPathNode } from '../board-orgpath.store';

const defaultSchema: IProperties = { type: 'string', pattern: '^[a-zA-Z0-9]*$' };

@Component({
  selector: 'amp-board-orgpath-node-editor',
  templateUrl: './board-orgpath-node-editor.component.html',
  styleUrls: ['./board-orgpath-node-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrgpathNodeEditorComponent implements OnInit, OnDestroy {
  @Input() set node(val: IOrgPathNode) {
    this.nodeId$.next(val?.id);
  }

  @Output() openEditTagPanel = new EventEmitter<void>();
  @Output() openAddTagPanel = new EventEmitter<void>();

  tagsDefinitions$: Observable<Record<string, TagKeyDescDTO>>;

  tagsOptions$: Observable<IOptionData<IProperties>[]>;

  nodeId$ = new BehaviorSubject<string>(null);

  node$: Observable<IOrgPathNode>;

  nodeSchema$: Observable<IProperties>;

  parentSchema$: Observable<IProperties>;

  jsonSchemaSettings$: Observable<{ jsonSchema: IJsonSchema; formGroup: UntypedFormGroup; groupProperties: Partial<IGroupProperties>[] }>;

  descriptionForm = new FormGroup({
    title: new FormControl(''),
    description: new FormControl(''),
    examples: new FormArray([new FormControl('')]),
    default: new FormControl(''),
  });

  enableCondition = false;

  currentConstraintType: ConstraintTypeEnum;

  componentDestroyed$: Subject<void> = new Subject();

  constraintTypes = ConstraintTypes.filter((e) => e !== ConstraintTypeEnum.schema && e !== ConstraintTypeEnum.alphanumeric);

  constraintTypeEnum = ConstraintTypeEnum;

  constructor(private _orgPathStore: BoardOrgpathStore, private _jsonSchemaFactoryService: JsonSchemaFormFactoryService, private _transloco: TranslocoService) {}

  ngOnInit(): void {
    this.tagsDefinitions$ = this._orgPathStore.selectTagsDefinitions$;

    // Node currently being displayed
    this.node$ = combineLatest([this._orgPathStore.selectNodeEntities$, this.nodeId$]).pipe(map(([nodeEntities, nodeId]) => nodeEntities[nodeId]));

    this.tagsOptions$ = this.tagsDefinitions$.pipe(map((tags) => Object.keys(tags).map((tagName) => ({ label: tagName, value: tagName, data: tags[tagName].schema as IProperties }))));

    this.nodeSchema$ = combineLatest([this.node$, this.tagsDefinitions$]).pipe(
      filter(([node, tagsDef]) => !!(node && tagsDef)),
      map(([node, tagsDef]) => {
        if (node.tag) {
          return tagsDef[node.tag].schema as IProperties;
        } else if (node.property) {
          return node.schema || defaultSchema;
        } else {
          return defaultSchema;
        }
      })
    );

    this.parentSchema$ = combineLatest([this.node$, this.tagsDefinitions$]).pipe(
      map(([node, tagsDef]) => {
        const parentNode = node?.parent;

        return tagsDef[parentNode?.tag]?.schema as IProperties;
      })
    );

    this.jsonSchemaSettings$ = this.parentSchema$.pipe(
      map((schema) => {
        if (schema) {
          const jsonSchema = { type: 'object', properties: { value: schema }, required: ['value'] } as IJsonSchema;
          return {
            jsonSchema,
            formGroup: this._jsonSchemaFactoryService.buildFormGroup(jsonSchema),
            groupProperties: this._jsonSchemaFactoryService.buildGroupProperties(jsonSchema),
          };
        } else {
          return undefined;
        }
      })
    );

    // Everytime the form changes, update the description in the schema
    this.descriptionForm.valueChanges
      .pipe(
        skip(1), // Skip first event when form is initialized
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((value) => {
        this._orgPathStore.editPropertyDescription(value as IProperties);
      });

    // Initialize the description form for property name
    this.nodeSchema$.pipe(first()).subscribe((nodeSchema) => this.descriptionForm.patchValue(nodeSchema));
  }

  onClose(): void {
    this._orgPathStore.selectNode(undefined); // unselect node
  }

  changePropertyTag(isTag: boolean): void {
    if (isTag) {
      this.updateTagProperty('');
    } else {
      this.updateTagProperty('name');
    }
  }

  onConditionChange({ status }: { status: boolean }): void {
    if (!status) {
      this._orgPathStore.editNodeCondition(undefined); // Remove condition
    }

    this.enableCondition = status;
  }

  updateCondition({ emitResult }): void {
    if (!emitResult.schemaErrors) {
      this._orgPathStore.editNodeCondition(emitResult.schemaValue?.value);
    }
  }

  updateTagProperty(value: string): void {
    this._orgPathStore.editNodeTagOrProperty(value);
  }

  updatePropertySchema(value: IJsonSchemaResult): void {
    this.nodeSchema$.pipe(first()).subscribe((schema) => {
      if (
        // If new schema is valid
        value.valid &&
        // and schema constraints are different than original
        (schema.type !== value.schema.type ||
          schema.minimum !== value.schema.minimum ||
          schema.maximum !== value.schema.maximum ||
          schema.enum?.join() !== value.schema.enum?.join() ||
          schema.maxLength !== value.schema.maxLength ||
          schema.minLength !== value.schema.minLength ||
          schema.pattern !== value.schema.pattern)
      ) {
        this._orgPathStore.editPropertySchema(value.schema);
      }
    });
  }

  getInvalidTag(): Observable<ITagChangeSummary[]> {
    return of([]);
  }

  getHeaderTitle$(): Observable<string> {
    return this.node$.pipe(
      filter((node) => !!node),
      switchMap((node) => {
        if (!node.tag && node.property !== 'name') {
          return of(this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.CREATE_NEW_LEVEL_60'));
        } else if (node.tag) {
          return this.getTagTitle$(node.tag).pipe(
            map((title) => title ?? this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.TAG_30'))
          );
        } else {
          return of(this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.NAME_30'));
        }
      })
    );
  }

  getTagTitle$(tag: string): Observable<string> {
    return this.tagsDefinitions$.pipe(
      first(),
      map((tagDef) => (tagDef?.[tag]?.schema as IProperties)?.title)
    );
  }

  onSchemaTypeChanged(option: ConstraintTypeEnum): void {
    this.currentConstraintType = option;

    // Update node to default schema
    this.updatePropertySchema({ valid: true, schema: this.getDefaultSchema(option) });
  }

  openEditPanel(): void {
    this.openEditTagPanel.emit();
  }

  openAddPanel(): void {
    this.openAddTagPanel.emit();
  }

  getJSONSchemaType(schema: IProperties): ConstraintTypeEnum {
    return getJSONSchemaType(schema);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.nodeId$.complete();
  }

  getDefaultSchema(option: ConstraintTypeEnum): IProperties {
    switch (option) {
      case ConstraintTypeEnum.specific:
        return { type: 'string', enum: [] };
      case ConstraintTypeEnum.text:
        return { type: 'string', pattern: buildJSONSchemaRegex('any', false, false) };
      case ConstraintTypeEnum.alphanumeric:
        return { type: 'string', pattern: buildJSONSchemaRegex('any', false, false) };
      case ConstraintTypeEnum.numeric:
        return { type: 'integer' };
      case ConstraintTypeEnum.boolean:
        return { type: 'boolean' };
      case ConstraintTypeEnum.schema:
        return { type: 'object' };
      default:
        return { type: 'string' };
    }
  }
}
