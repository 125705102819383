<avn-dialog-layout>
  <avn-dialog-header [showCloseIcon]="dialogConfig.showCloseIcon" [title]="createMode | applyFunction: createAssignLabel:[]:this | transloco" (closeClicked)="onClose()"></avn-dialog-header>
  <avn-dialog-content class="tag-creation-modal-body-content">
    <ng-container *ngIf="tagKeysOptionList$ | async as options">
      <avn-autocomplete
        class="tag-creation-modal-dropdown"
        [placeholder]="'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.CREATE_TAG.ENTER_A_VALUE_100' | transloco"
        [maxOptionsVisibleInViewport]="7"
        [value]=""
        [datasource]="options"
        [highlightMatchingText]="true"
        [allowNonExistingValues]="false"
        (valueChanged)="onKeyChanged($event)"
      >
      </avn-autocomplete>
      <avn-json-schema-form
        *ngIf="currentSchema$ | async as schema"
        class="json-schema-form"
        [jsonSchema]="schema.jsonSchema"
        [jsonSchemaForm]="schema.jsonSchemaFormGroup"
        [groupProperties]="schema.jsonSchemaGroupProperties"
        [initialValues]="initialValues$ | async"
        (schemaResult)="schemaResult($event)"
      >
      </avn-json-schema-form>
      <div *ngIf="currentKey$ | async | applyFunction: getOwnerProperty as owner" class="owner">
        <label>{{ 'engineTagManager.CONTROL.APP_OWNER.TITLE' | transloco }}: </label>
        <span>{{ owner }}</span>
      </div>
    </ng-container>
  </avn-dialog-content>

  <avn-dialog-footer class="row-flex">
    <avn-button [label]="'button.cancel' | transloco" (actioned)="onClose()"></avn-button>
    <avn-button [disabled]="!form?.valid" [label]="createMode | applyFunction: createAssignLabel:[]:this | transloco" (actioned)="onSave()" avnTheme="primary"></avn-button>
  </avn-dialog-footer>
</avn-dialog-layout>
