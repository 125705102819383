import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router } from '@angular/router';
import { AuthFacade } from '@amp/auth';
import { Observable, of } from 'rxjs';
import { mergeMap, switchMap, take } from 'rxjs/operators';

/** Check if user has unrestricted scope */
@Injectable({ providedIn: 'root' })
export class UnrestrictedScopeGuard implements CanActivate, CanLoad {

  constructor(private router: Router, private authFacade: AuthFacade) {}

  canLoad(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }

  canActivate(_): Observable<boolean> {
    return this.authFacade.authStateReady$.pipe(
      take(1),
      switchMap(() => this.authFacade.authenticatedUserScopes$),
      mergeMap((scopes) => {
        if ((scopes || []).some((scope) => !!scope?.authorizeAllDeviceGroups)) {
          return of(true);
        } else {
          this.router.navigate(['forbidden']);
          return of(false);
        }
      })
    );
  }
}
