<div class="content" [style.borderLeft]="'5px solid ' + ownerConfig?.color">
  <div [ngClass]="{ edited: isEdited }">
    <div class="label-container">
      <label #editOrigin="cdkOverlayOrigin" cdkOverlayOrigin [avnTooltip]="!isEdited ? '' : ('tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.TAG_ITEM.EDITED_60' | transloco)" tooltipPlacement="top"
        >{{_tag | applyFunction:getLabel}} <span *ngIf="isEdited">*</span></label
      >
      <avn-icon *ngIf="_tag.keyDescription?.schema | applyFunction: infoExist:[]:this" [fontSize]="20" [icon]="'action:info'" avnTooltip [tooltipTemplate]="tooltipTemp" tooltipPlacement="left">
      </avn-icon>
    </div>
    <div class="tag-values" *ngIf="currentSchema">
      <avn-json-schema-form
        class="json-schema-form"
        [jsonSchema]="currentSchema.jsonSchema"
        [jsonSchemaForm]="currentSchema.jsonSchemaFormGroup"
        [groupProperties]="currentSchema.jsonSchemaGroupProperties"
        [initialValues]="formValues"
        (schemaResult)="applyTagChanges(currentSchema.jsonSchemaFormGroup)"
      ></avn-json-schema-form>
      <avn-icon *ngIf="isEdited && ownerConfig?.external" [fontSize]="26" [avnTheme]="themeType.WARNING" [icon]="'communication:sms_failed'"></avn-icon>
      <div class="tag-action">
        <avn-icon *ngIf="isDeletable" [fontSize]="20" [icon]="'action:delete'" (click)="onDeleteTag()" [avnTooltip]="'button.delete' | transloco" [tooltipPlacement]="'top'"></avn-icon>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltipTemp>
  <div style="font-size: 11px">
    <div *ngIf="_tag.keyDescription?.schema?.description">
      <span style="font-weight: bold">{{ 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.TAG_ITEM.DESCRIPTION_20' | transloco }}: </span>{{ _tag.keyDescription?.schema?.description }}
    </div>
    <div *ngIf="_tag.keyDescription?.schema?.default">
      <span style="font-weight: bold">{{ 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.TAG_ITEM.DEFAULT_20' | transloco }}: </span>{{ _tag.keyDescription?.schema?.default }}
    </div>
    <div *ngIf="_tag.keyDescription?.schema?.examples | customFn: exampleExist">
      <span style="font-weight: bold">{{ 'tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.TAG_ITEM.EXAMPLE_20' | transloco }}: </span>{{ _tag.keyDescription?.schema?.examples }}
    </div>
  </div>
</ng-template>
