import { TagKeyDescDTO } from '@activia/cm-api';
import { ConstraintTypeEnum as JsonSchemaConstraintTypeEnum, IJsonSchema, IProperties, JsonSchemaFormFactoryService } from '@activia/json-schema-forms';
import { AssetTagKeyDescDTO } from '@amp/cms-api';
import { IJsonSchemaWrapper } from '../model/tag-values-operations.interface';
import { ThemeType } from '@activia/ngx-components';

export type ConstraintTypeEnum = JsonSchemaConstraintTypeEnum;
export const ConstraintTypeEnums: Record<ConstraintTypeEnum, { theme: ThemeType }> = {
  specific: { theme: ThemeType.INFO },
  alphanumeric: { theme: ThemeType.HIGHLIGHT },
  text: { theme: ThemeType.PRIMARY },
  numeric: { theme: ThemeType.SECONDARY },
  boolean: { theme: ThemeType.SECONDARY },
  schema: { theme: ThemeType.SUCCESS },
};
export const ConstraintTypes = Object.keys(ConstraintTypeEnums) as ConstraintTypeEnum[];

/** schema tag key must have a suffix of .json */
export const SCHEMA_TAG_SUFFIX = '.json';

/** tag missing schema or schema is empty */
export const hasBadSchema = (description: TagKeyDescDTO): boolean => !description?.schema || Object.keys(description.schema).length === 0;

/** Check if this is a enum schema */
export const hasEnumSchema = (description: TagKeyDescDTO | AssetTagKeyDescDTO) => description.schema && 'enum' in description?.schema;

/** The schema that is able to assign multiple tag value */
export const ableToAssignMultiValue = (type: ConstraintTypeEnum): boolean => {
  switch (type) {
    case JsonSchemaConstraintTypeEnum.schema:
      return false;
    case JsonSchemaConstraintTypeEnum.boolean:
      return false;
    default:
      return true;
  }
};

/** Custom requirement for advanced schema tag key: must have .json suffix */
export const adjustTagKeyCustomRequirement = (keyName: string, type: ConstraintTypeEnum) => {
  const hasSuffix = !!keyName && keyName.lastIndexOf(SCHEMA_TAG_SUFFIX) !== -1;
  if (type === 'schema' && !hasSuffix) {
    return `${keyName}${SCHEMA_TAG_SUFFIX}`;
  }
  return keyName;
};

/** Get schema, form group and properties from a tag key */
export const getJsonSchema = (key: string, description: TagKeyDescDTO | AssetTagKeyDescDTO, jsonSchemaFactoryService: JsonSchemaFormFactoryService): IJsonSchemaWrapper => {
  if (description) {
    // Tag key exist
    const schema: IJsonSchema = { properties: { [key]: sanitizeMultiValuesJsonSchema(description) }, type: 'object' };
    return {
      jsonSchema: schema,
      jsonSchemaFormGroup: jsonSchemaFactoryService.buildFormGroup(schema, endWithDotJson),
      jsonSchemaGroupProperties: jsonSchemaFactoryService.buildGroupProperties(schema, endWithDotJson),
    };
  } else {
    return undefined;
  }
};

export const endWithDotJson = (key: string): boolean => key.endsWith('.json');

/** Transform a multivalues schema into type array */
export const sanitizeMultiValuesJsonSchema = (jsonSchema: AssetTagKeyDescDTO | TagKeyDescDTO): IProperties => {
  if (!jsonSchema.schema || Object.keys(jsonSchema.schema).length === 0) {
    return { type: undefined };
  }

  if (jsonSchema.multivalues) {
    const { pattern, type, enum: enumValues, minLength, maxLength, ...schema } = jsonSchema.schema as IProperties;

    return {
      ...schema, // Keep all properties except type, pattern and enum
      type: 'array', // Override type for array
      items: {
        // Apply pattern, enum, type, minLength and maxLength to items inside the array
        type,
        pattern,
        enum: enumValues,
        minLength,
        maxLength,
      },
    };
  } else {
    return jsonSchema.schema as IProperties;
  }
};
