import { IGroupProperties, IJsonSchema, IProperties, JsonSchemaFormFactoryService } from '@activia/json-schema-forms';
import { IOrgPathDefNode } from '@amp/tag-operation';
import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, QueryList, ViewChildren, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'amp-board-org-path-form',
  templateUrl: './board-org-path-form.component.html',
  styleUrls: ['./board-org-path-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoardOrgPathFormComponent implements OnInit, OnDestroy {
  /** List of SideTabComponent inside a SideTabGroup */
  @ViewChildren(BoardOrgPathFormComponent) dependentChildren: QueryList<BoardOrgPathFormComponent>;

  /** Form array to be hooked with */
  @Input() form: UntypedFormArray;

  /** The dependent json schema to build the form */
  @Input() nodeDefinition: IOrgPathDefNode;

  /** Tag key schema used in the orgpath definition */
  @Input() tagDefinition: Record<string, IProperties>;

  /** (Optional) Initial value of the form control and its children */
  @Input() initialValues?: Record<string, any>;

  /** Emit resulting value for every changes */
  @Output() schemaResult = new EventEmitter();

  /** Initial value of the form control */
  initialValue: Record<string, any>;

  /** Current value of the form control */
  schemaValue: string;

  jsonSchemaSettings: { jsonSchema: IJsonSchema; formGroup: UntypedFormGroup; groupProperties: Partial<IGroupProperties>[] };

  constructor(private _jsonSchemaFactoryService: JsonSchemaFormFactoryService, private cdr: ChangeDetectorRef, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    // Get schema from definition
    const schema = this.nodeDefinition.tag ? this.tagDefinition[this.nodeDefinition.tag] : this.nodeDefinition.schema;

    // Initialize json schema settings
    const jsonSchema: IJsonSchema = { type: 'object', properties: { value: schema }, required: ['value'] };

    this.jsonSchemaSettings = {
      jsonSchema,
      formGroup: this._jsonSchemaFactoryService.buildFormGroup(jsonSchema),
      groupProperties: this._jsonSchemaFactoryService.buildGroupProperties(jsonSchema),
    };

    if (!this.form) {
      this.form = this.fb.array([]);
    }

    // Get the initial value of the form control
    this.schemaValue = this.initialValues?.[this.nodeDefinition.tag || this.nodeDefinition.property];
    this.initialValue = { value: this.schemaValue };

    // Register json schema form
    this.form.push(this.jsonSchemaSettings.formGroup);
  }

  getChildResult(childrenValue: Record<string, unknown>) {
    const property = this.nodeDefinition.tag || this.nodeDefinition.property;
    this.schemaResult.emit({ ...childrenValue, [property]: this.schemaValue, error: this.form.invalid });
  }

  updateCurrentSchemaValue(result) {
    if (result?.emitResult && !result.emitResult.schemaErrors) {
      this.schemaValue = result.emitResult.schemaValue.value;
      const property = this.nodeDefinition.tag || this.nodeDefinition.property;

      this.initialValues = { [property]: [this.schemaValue] };

      this.cdr.detectChanges(); // Update dependent children in the DOM (Update QueryList dependentChildren)

      // Everytime we change a control, re-validate every other controls
      this.form.controls.forEach((jsonFormGroup) => {
        jsonFormGroup.get('value').updateValueAndValidity();
      });

      this.schemaResult.emit({ ...this.getCurrentSchemaValue(), error: this.form.invalid });
    } else {
      this.schemaResult.emit({ error: true }); // If form is not valid send an error
    }
  }

  getCurrentSchemaValue(): Record<string, unknown> {
    // Get all children schema value and merge them in 1 object
    const childrenValue = this.dependentChildren.reduce((acc, curr) => ({ ...acc, ...curr.getCurrentSchemaValue() }), {});

    const property = this.nodeDefinition.tag || this.nodeDefinition.property;

    // Add value to property
    return {
      ...childrenValue,
      ...(this.schemaValue ? { [property]: this.schemaValue } : {}),
    };
  }

  ngOnDestroy(): void {
    this.form.removeAt(this.form.controls.findIndex((e) => e === this.jsonSchemaSettings.formGroup));
  }
}
