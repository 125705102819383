import { IModalComponent, IModalConfig, IOptionData, MODAL_CONFIG, ModalRef } from '@activia/ngx-components';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { ISchemaResult, JsonSchemaFormFactoryService } from '@activia/json-schema-forms';
import { TagValueCreationConfig } from '../../model/tag-value-creation-config.interface';
import { ITagValue } from '../../model/tag-value.interface';
import { PropertyType } from '../../model/operation-scope.interface';
import { AssetTagKeyDescDTO } from '@amp/cms-api';
import { TagKeyDescDTO } from '@activia/cm-api';
import { getJsonSchema } from '../../utils/schema-helper';
import { IJsonSchemaWrapper } from '../../model/tag-values-operations.interface';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'amp-tag-value-creation-modal',
  templateUrl: './tag-value-creation-modal.component.html',
  styleUrls: ['./tag-value-creation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagValueCreationModalComponent implements IModalComponent, OnInit, OnDestroy {
  @Input() createMode = true;

  /** List of tags to be created when user confirm */
  @Output() actioned = new EventEmitter<ITagValue>();

  /** The list of tag keys for dropdown */
  tagKeysOptionList$: Observable<IOptionData<TagKeyDescDTO | AssetTagKeyDescDTO>[]>;

  /** Current key being selected */
  currentKey$ = new BehaviorSubject<{ key: string; propertyType: PropertyType; description: TagKeyDescDTO | AssetTagKeyDescDTO }>({
    key: '',
    propertyType: undefined,
    description: undefined,
  });

  /** Schema, formGroup and properties to use JsonSchemaComponent  */
  currentSchema$: Observable<IJsonSchemaWrapper> = new Subject();

  /** Json schema form */
  form: UntypedFormGroup;

  /** Initial value for tooltip when editing tags */
  initialValues$: Observable<Record<string, unknown>>;

  private _existingTags: ITagValue[];

  constructor(
    private _dialogRef: ModalRef<TagValueCreationModalComponent>,
    @Inject(MODAL_CONFIG) public dialogConfig: IModalConfig<TagValueCreationConfig>,
    private _jsonSchemaFactoryService: JsonSchemaFormFactoryService,
    private _cdr: ChangeDetectorRef
  ) {
    this._existingTags = dialogConfig.data.existingTags;
    this.tagKeysOptionList$ = dialogConfig.data.keyOptions$;
  }

  public ngOnInit(): void {
    this.currentSchema$ = this.currentKey$.pipe(
      switchMap(({ key, description }) => of(getJsonSchema(key, description, this._jsonSchemaFactoryService))),
      tap((wrapper) => {
        this.form = wrapper?.jsonSchemaFormGroup;
      })
    );
    this.initialValues$ = this.currentKey$.pipe(
      map(({ key, propertyType }) => this._existingTags.find((e) => e.key === key && e.propertyType === propertyType)),
      map((tag) => tag && { [tag.key]: tag.values })
    );
  }

  public schemaResult(result: { emitResult: Partial<ISchemaResult>; jsonSchemaForm: UntypedFormGroup }) {
    this.form = result.jsonSchemaForm;
    this._cdr.detectChanges();
  }

  public onSave() {
    if (this.form?.valid) {
      this.currentKey$.pipe(take(1)).subscribe(({ key, propertyType, description }) => {
        this.actioned.emit({ key, values: [this.form.value[key]].flat(), propertyType, keyDescription: description });
        this.onClose();
      });
    }
  }

  public onKeyChanged(option: IOptionData<TagKeyDescDTO | AssetTagKeyDescDTO>) {
    this.currentKey$.next(
      !!option
        ? {
            key: option.value || option,
            propertyType: option.group as PropertyType,
            description: option.data,
          }
        : { key: '', propertyType: undefined, description: undefined }
    );
  }

  public canClose(): boolean {
    return false;
  }

  public onClose() {
    this._dialogRef.close();
  }

  public ngOnDestroy() {
    this.currentKey$.complete();
  }

  public createAssignLabel(createMode: boolean): string {
    return `tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.CREATE_TAG.${createMode ? 'CREATE' : 'ASSIGN'}_TAG_50`;
  }

  public getOwnerProperty({ description }): string {
    return (description as TagKeyDescDTO)?.owner ? description?.owner : '';
  }
}
