import { SiteDTO } from '@activia/cm-api';
import { IModalComponent, IModalConfig, ModalRef, MODAL_CONFIG, ThemeType } from '@activia/ngx-components';
import { Component, OnInit, ChangeDetectionStrategy, Inject, Output, EventEmitter } from '@angular/core';
import { IBoardCreationTemplate, IExperienceTemplate } from '../../../models/experience-template.interface';

@Component({
  selector: 'amp-experience-template-creation-modal',
  templateUrl: './experience-template-creation-modal.component.html',
  styleUrls: ['./experience-template-creation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTemplateCreationModalComponent implements IModalComponent, OnInit {
  @Output() createBoards = new EventEmitter<IExperienceTemplate>();

  @Output() createBoardsWithDevices = new EventEmitter<IBoardCreationTemplate[]>();

  /** Selected step of the stepper */
  selectedIndex = 0;

  themeType = ThemeType;

  experienceTemplate: IExperienceTemplate;

  site: SiteDTO;
  siteId: number;
  siteExternalId: string;

  constructor(
    private modalRef: ModalRef<ExperienceTemplateCreationModalComponent>,
    @Inject(MODAL_CONFIG)
    public dialogConfig: IModalConfig<{ site: SiteDTO; experienceTemplate: IExperienceTemplate }>
  ) {
    this.site = dialogConfig.data.site;
    this.experienceTemplate = dialogConfig.data.experienceTemplate;
  }

  ngOnInit(): void {}

  canClose(): boolean {
    return true;
  }

  onClose() {
    this.modalRef.close();
  }

  addBoards() {
    this.createBoards.emit(this.experienceTemplate);
    this.modalRef.close();
  }

  addBoardsWithDevices(boardsWithDevices: IBoardCreationTemplate[]) {
    this.createBoardsWithDevices.emit(boardsWithDevices);
    this.modalRef.close();
  }

  getNextStep(value) {
    switch (value) {
      case 'provision':
        this.selectedIndex = 1;
        break;
      case 'connect':
        this.addBoards();
        break;
      case 'close':
        this.onClose();
        break;
    }
  }
}
