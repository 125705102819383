import { getAsyncDataError, LoadingState } from '@activia/ngx-components';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDevicesState } from './devices.reducer';

export const devicesSelector = createFeatureSelector<IDevicesState>('devices');

export const sharedListsCounts = createSelector(devicesSelector, (devices) => devices.sharedListsDeviceCount);

export const sharedLists = createSelector(devicesSelector, (devices) => devices.sharedLists.data.dataset);

export const sharedListsState = createSelector(devicesSelector, (devices) => devices.sharedLists.state);

export const sharedListsLoading = createSelector(devicesSelector, (devices) => devices.sharedLists.state === LoadingState.LOADING);

export const sharedListsLoaded = createSelector(devicesSelector, (devices) => devices.sharedLists.state === LoadingState.LOADED);

export const sharedListsError = createSelector(devicesSelector, (devices) => getAsyncDataError(devices.sharedLists.state));

export const newShared = createSelector(devicesSelector, (devices) => devices.sharedLists.data.new);

export const exportTasks = createSelector(devicesSelector, (devices) => devices.exportTasks);

export const devicesSelectors = {
  sharedListsCounts,
  sharedLists,
  sharedListsState,
  sharedListsLoading,
  sharedListsLoaded,
  sharedListsError,
  newShared,
  exportTasks,
};
