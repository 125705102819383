import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { first, mapTo, tap } from 'rxjs/operators';
import { LoadingState } from '@activia/ngx-components';
import * as ExperienceTemplateAction from '../store/experience-template/experience-template.actions';
import { select, Store } from '@ngrx/store';
import * as ExperienceTemplateSelectors from '../store/experience-template/experience-template.selectors';
import { IExperienceTemplate } from '../models/experience-template.interface';

@Injectable()
export class PreloadExperienceTemplateGuard implements CanActivate {
  constructor(private _store: Store<IExperienceTemplate>) {}

  canActivate(): Observable<boolean> {
    return this._store
      .pipe(
        select(ExperienceTemplateSelectors.getExperienceTemplatesDataState),
        first(),
        tap((state) => {
          if (state !== LoadingState.LOADING && state !== LoadingState.LOADED) {
            this._store.dispatch(ExperienceTemplateAction.FetchExperienceTemplates());
          }
        }),
        mapTo(true)
      );
  }
}
