import { Pipe, PipeTransform } from '@angular/core';

/**
 * Convert long number into abbreviated string
 * ex: 1000 => 1.0K
 *     1200 => 1.2K
 *     1300000 => 1.3M
 */
@Pipe({ name: 'count' })
export class CountPipe implements PipeTransform {
  transform(count: number = 0): string {
    if (isNaN(parseFloat(String(count))) || !isFinite(count)) {
      return '?';
    }

    if (count >= 1000000) {
      const x = count / 1000000;
      if (x < 10) {
        return x.toFixed(1) + 'M';
      }
      return Math.round(x) + 'M';
    }
    if (count >= 1000) {
      const x = count / 1000;
      if (x < 10) {
        return x.toFixed(1) + 'K';
      }
      return Math.round(x) + 'K';
    }

    return String(count);
  }
}
