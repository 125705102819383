import { TagKeyDescDTO } from '@activia/cm-api';
import { IProperties } from '@activia/json-schema-forms';
import { AsyncDataState, ModalService } from '@activia/ngx-components';
import { EngineTagLevel, ITagChangeSummary, ITagOperationChange } from '@amp/tag-operation';
import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { combineLatest, distinctUntilChanged, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { ISaveBeforeExit } from '../../guards/save-before-exit.guard';
import { BoardOrgpathAddTagModalComponent } from './board-orgpath-add-tag-modal/board-orgpath-add-tag-modal.component';
import { BoardOrgpathStore, IOrgPathNode } from './board-orgpath.store';

@Component({
  selector: 'amp-board-orgpath',
  templateUrl: './board-orgpath.component.html',
  styleUrls: ['./board-orgpath.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [BoardOrgpathStore],
})
export class BoardOrgpathComponent implements OnInit, OnDestroy, ISaveBeforeExit {
  boardOrgPathDef$: Observable<IOrgPathNode>;

  boardTagsDefinition$: Observable<Record<string, TagKeyDescDTO>>;

  boardOrgPathState$: Observable<AsyncDataState>;

  isSaved$: Observable<boolean>;

  hasErrors$: Observable<boolean>;

  componentDestroyed$: Subject<void> = new Subject();

  /** Attributes used for animation in the detail panel */
  activeDetailView: { id: string };
  index = 0;
  previousNode: IOrgPathNode;
  activeNode: IOrgPathNode;

  engineTagLevel = EngineTagLevel;

  constructor(private _boardOrgPathStore: BoardOrgpathStore, private _modalService: ModalService) {}

  ngOnInit(): void {
    this.boardOrgPathDef$ = this._boardOrgPathStore.selectOrgPathDefinition$;
    this.boardTagsDefinition$ = this._boardOrgPathStore.selectTagsDefinitions$;
    this.boardOrgPathState$ = this._boardOrgPathStore.selectLoadingState$;
    this.isSaved$ = combineLatest([this._boardOrgPathStore.selectIsSaved$, this._boardOrgPathStore.selectErrors$]).pipe(map(([isSaved, errors]) => isSaved || !!Object.keys(errors).length));
    this.hasErrors$ = this._boardOrgPathStore.selectErrors$.pipe(map((errors) => !!Object.keys(errors)?.length));

    // Manage switch view animation in detail
    this._boardOrgPathStore.selectedNode$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((newNode) => {
        if (newNode) {
          this.previousNode = this.activeNode;
          this.activeNode = newNode;
          this.index = (this.index + 1) % 2;
          this.activeDetailView = { id: this.index.toString() };
        } else {
          this.previousNode = this.activeNode = this.activeDetailView = undefined;
          this.index = 0;
        }
      });
  }

  /** Check if there is unsaved changes in the board org path tree */
  hasUnsavedChanges(): boolean {
    return this._boardOrgPathStore.hasUnsavedChanges();
  }

  save(): Observable<boolean> {
    return this._boardOrgPathStore.saveDefinition();
  }

  getSchemaFromTagDesc(tags: Record<string, TagKeyDescDTO>): Record<string, IProperties> {
    return Object.keys(tags).reduce((acc, curr) => {
      acc[curr] = tags[curr].schema as IProperties;
      return acc;
    }, {} as Record<string, IProperties>);
  }

  openEditTagPanel(): void {
    this.activeDetailView = { id: '2' };
  }

  getInvalidTag(): Observable<ITagChangeSummary[]> {
    return of([]);
  }

  addFirstNode(): void {
    this._boardOrgPathStore.addRootNode();
  }

  editTag(tag: { key: string; description: TagKeyDescDTO; operations: ITagOperationChange }): void {
    this._boardOrgPathStore.saveTag(tag);
  }

  goToCurrentNode(): void {
    this.activeDetailView = this.activeNode ? { id: this.index.toString() } : undefined;
  }

  openAddTagPanel(): void {
    const modalRef = this._modalService.open<BoardOrgpathAddTagModalComponent, void>(
      BoardOrgpathAddTagModalComponent,
      {
        showCloseIcon: true,
      },
      {
        width: '95vw',
        height: '95vh',
      }
    );

    modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed), takeUntil(this.componentDestroyed$)).subscribe((newTag) => {
      this._boardOrgPathStore.addNewTag(newTag);
    });
  }

  onSaveBoardOrgPathDef(): void {
    this._boardOrgPathStore.saveDefinition();
  }

  openPreview(open: boolean) {
    if (open) {
      this.activeDetailView = { id: '3' };
    } else {
      this.goToCurrentNode();
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
