import { ThemeType } from '@activia/ngx-components';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'amp-key-metrics-accordion',
  templateUrl: './key-metrics-accordion.component.html',
  styleUrls: ['./key-metrics-accordion.component.scss'],
})
export class KeyMetricsAccordionComponent implements OnInit {
  // keymetrics accordion header
  @Input() keyMetricsAccordionHeader: string;

  // keymetrics accordion content
  @Input() keyMetricsAccordionContent: TemplateRef<any>;

  // Theme type of this accordion
  @Input() keyMetricsAccordionBorderColor: string;

  // open the accordion
  showAccordionContent = false;

  constructor() {}

  ngOnInit() {
    this.showAccordionContent = this.keyMetricsAccordionBorderColor === ThemeType.DANGER ? true : false;
  }
}
