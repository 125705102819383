<ng-container *ngIf="nodeDefinition">
  <avn-json-schema-form
    [jsonSchema]="jsonSchemaSettings.jsonSchema"
    [jsonSchemaForm]="jsonSchemaSettings.formGroup"
    [groupProperties]="jsonSchemaSettings.groupProperties"
    [initialValues]="initialValue"
    (schemaResult)="updateCurrentSchemaValue($event)"
  ></avn-json-schema-form>

  <ng-container *ngIf="nodeDefinition.childOneOf as dependencies">
    <ng-container *ngFor="let childSchema of dependencies">
      <ng-container *ngIf="!childSchema.dependentItem || childSchema.dependentItem === schemaValue">
        <amp-board-org-path-form [form]="form" [nodeDefinition]="childSchema" [initialValues]="initialValues" [tagDefinition]="tagDefinition" (schemaResult)="getChildResult($event)">
        </amp-board-org-path-form>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
