import { SiteDTO } from '@activia/cm-api';
import {
  dataOnceReady,
  DataTableComponent,
  IDataTableColumnConfig,
  IDataTableColumnSortEvent,
  IDataTableDataSource,
  IModalConfig,
  ISort,
  IStandardDialogData,
  LoadingState,
  ModalDialogType,
  ModalService,
  ModalType,
  ThemeType,
} from '@activia/ngx-components';
import { ISiteManagementConfig, SITE_MANAGEMENT_MODULE_CONFIG } from '@amp/environment';
import { RouterFacade } from '@amp/router-store';
import { Overlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, from, Observable, ReplaySubject, Subject, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, share, switchMap, take, takeUntil } from 'rxjs/operators';
import { CMRole } from '@amp/auth';
import { select, Store } from '@ngrx/store';
import { ISiteManagementState } from '../../store/site-management.reducer';
import * as SiteManagementAction from '../../store/site-management.actions';
import { getNewPathUrl, searchSites } from '../../utils/site.utils';
import { siteManagementEntities } from '../../store/site-management.selectors';
import { SiteManagementSiteInfoCreatorModalComponent } from '../../components/site-management-site-info-creator-modal/site-management-site-info-creator-modal.component';
import { SiteColumnsService } from '../services/site-columns.service';
import { STANDARD_SITE_COLS } from '../models/site-column';
import { siteSyncSelectors } from '../../store/site-sync/site-sync.selectors';
import { SyncModalService } from '../../services/sync-modal.service';
import { SiteManagementSettingsDrawerComponent } from '../../components/experience-template/site-management-settings-drawer/site-management-settings-drawer.component';
import { geoFixerEntities } from '../../store/geo-fixer/geo-fixer.selectors';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { CustomSiteSelectionModalComponent } from '../../components/custom-site-selection-modal/custom-site-selection-modal.component';

const routerAnimation = trigger('routerAnimation', [transition('* => *', [style({ transform: 'translateX(100%)' }), animate('300ms ease-in', style({ transform: 'translateY(0%)' }))])]);

@Component({
  selector: 'amp-site-list',
  templateUrl: './site-list.component.html',
  styleUrls: ['./site-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [routerAnimation],
})
export class SiteListComponent implements OnDestroy {
  @ViewChild('siteListActions', { static: false }) siteListActionsTemplate: TemplateRef<void>;
  @ViewChild('dataTableComponent') dataTableComponent: DataTableComponent<any, any>;

  /** list of the sites to display */
  dataSource$: Observable<IDataTableDataSource<SiteDTO>>;

  /** The recent devices data table columns configuration */
  columns$: Observable<IDataTableColumnConfig<void>[]>;

  /** The search text filter **/
  siteListSearchTextFilter$: Observable<string>;

  isLoadingGeoProblematicSites$: Observable<boolean> = this._store.pipe(geoFixerEntities.isLoadingAllGeoProblematicSites$);
  geoProblematicSitesCount$: Observable<number> = this._store.pipe(geoFixerEntities.geoProblematicSitesCount$);

  /** display dataTable loader */
  isSiteListLoading$: Observable<boolean> = this._store.pipe(siteManagementEntities.allSitesDataState$).pipe(map((loadingState) => loadingState === LoadingState.LOADING));
  siteListSort$: Observable<ISort> = this._store.pipe(siteManagementEntities.siteListSort$).pipe(take(1)); // only initial value is needed;

  /** IDs of sites that are custom-selected */
  selectedSiteIds$: Observable<number[]>;

  /** list of selected sites */
  selectedSitesList$: Observable<SiteDTO[]> = this._store.pipe(siteManagementEntities.selectedSites$);

  /** roles */
  CmRole = CMRole;

  /** Indicates if a site sync job is active **/
  hasSyncJobInProgress$: Observable<boolean> = this._store.pipe(select(siteSyncSelectors.hasSyncJobInProgress));

  activeDetail: { id: string } = null;

  breadcrumbs$: Observable<{ label: string; icon: string; path: string[] }[]>;

  currentSite$: Observable<SiteDTO> = this._store.pipe(siteManagementEntities.selectedCurrentSite$);

  /** Minimum of character allowed in the search bar */
  private _minChars = 3;
  private currentSiteId;

  /** @ignore **/
  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private _overlay: Overlay,
    private _modalService: ModalService,
    private _routerFacade: RouterFacade,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) private _siteManagementConfig: ISiteManagementConfig,
    public translateService: TranslocoService,
    private _store: Store<ISiteManagementState>,
    private _siteColumnService: SiteColumnsService,
    private _syncModalService: SyncModalService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    // Side effect when changing the site
    this.currentSite$
      .pipe(
        map((site) => site?.id),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((siteId) => {
        this.currentSiteId = siteId ? +siteId : undefined;
        this.activeDetail = siteId ? { id: 'siteDetail' } : null;
      });

    // synchronize the search field with the parameter of the path
    this.siteListSearchTextFilter$ = this._routerFacade.currentRoute$.pipe(
      map((r) => r.queryParams.q || null),
      distinctUntilChanged()
    );

    this.columns$ = this._siteColumnService.siteColumnsFormatted$(STANDARD_SITE_COLS).pipe(map((allCols) => this._addActionColumns(allCols)));
    const allSites$ = dataOnceReady(this._store.pipe(siteManagementEntities.allSitesData$), this._store.pipe(siteManagementEntities.allSitesDataState$)).pipe(
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    );
    this.dataSource$ = combineLatest([allSites$, this.siteListSearchTextFilter$.pipe(debounceTime(100))]).pipe(
      map(([allSites, searchText]) => {
        let sites = allSites;
        if (searchText) {
          sites = searchSites(allSites, searchText);
        }
        const newDataSource: IDataTableDataSource<SiteDTO> = {
          rows: sites,
          totalRowCount: sites.length,
        };
        return newDataSource;
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );

    this.breadcrumbs$ = this.currentSite$.pipe(
      filter((site) => !!site),
      map((site) => this.getBreadcrumbs(site))
    );
  }

  /** @ignore **/
  public ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }

  /**
   * filters sites matching search text
   */
  public filterSitesBySearchText(searchText: string) {
    searchText = searchText?.trim();
    const qp = { q: searchText || null };
    this._routerFacade.currentRoute$.pipe(take(1)).subscribe((route) => {
      const noNavigation = searchText?.length !== 0 && searchText?.length < this._minChars;
      if (!noNavigation) {
        this.redirect(['sites'], { queryParams: { ...route.queryParams, ...qp } });
      }
    });
  }

  /**
   * Open a modal to create a new site
   */
  public onCreateSite() {
    const modalRef = this._modalService.open(
      SiteManagementSiteInfoCreatorModalComponent,
      {
        closeOnBackdropClick: true,
      },
      {
        width: '600px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('50px'),
      },
      ModalType.Dialog
    );

    modalRef.componentInstance.saved.pipe(takeUntil(modalRef.afterClosed)).subscribe((siteId) => {
      // Redirect to editor page once site is created
      this.redirect([siteId]);
    });
  }

  onSelectSites() {
    const modalRef = this._modalService.open(
      CustomSiteSelectionModalComponent,
      {
        closeOnBackdropClick: true,
      },
      {
        width: '600px',
        panelClass: 'overlay-panel-class',
        positionStrategy: this._overlay.position().global().centerHorizontally().top('50px'),
      },
      ModalType.Dialog
    );

    this.selectedSiteIds$ = modalRef.componentInstance.saved.pipe(
      map((selectedSites) => selectedSites.map((site) => site.id)),
      takeUntil(modalRef.afterClosed)
    );
  }

  /**
   * Delete a site
   */
  public onDeletedSites(selectedSitesList: SiteDTO[], site?: SiteDTO) {
    const deletedSites = !!site ? Array(site) : selectedSitesList;
    const confirmTextValue = this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.DELETE_SITE.CONFIRMATION_TEXT_VALUE_50', { count: selectedSitesList.length });
    const dialogData: IStandardDialogData = {
      type: deletedSites.length === 1 ? ModalDialogType.Confirm : ModalDialogType.ConfirmText,
      theme: ThemeType.DANGER,
      title: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.DELETE_SITE.TITLE_30'),
      message:
        deletedSites.length === 1
          ? this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.DELETE_SITE.MESSAGE.SINGULAR_100', { siteName: deletedSites[0].name })
          : this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.DELETE_SITE.MESSAGE.PLURAL_100', { count: selectedSitesList.length }),
      confirmTextLabel: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.DELETE_SITE.CONFIRMTEXT_LABEL_100', { confirmTextValue }),
      confirmTextValue,
      confirmInputHelpTextError: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.DASHBOARD.DELETE_SITE.CONFIRM_INPUT_HELP_TEXT_ERROR_100', { confirmTextValue }),
      closeActionLabel: this.translateService.translate('button.cancel'),
      acceptActionLabel: this.translateService.translate('button.delete'),
    };

    const dialogConfig: IModalConfig<IStandardDialogData> = {
      showCloseIcon: true,
      closeOnBackdropClick: true,
      data: dialogData,
    };

    const modalRef = this._modalService.openStandardDialog(dialogConfig);
    modalRef.componentInstance.accepted.pipe(takeUntil(modalRef.afterClosed)).subscribe(() => {
      this._store.dispatch(SiteManagementAction.DeleteSites({ siteIds: deletedSites.map((s) => s.id) }));
      this.redirectAndClearActiveRow(['sites']);
    });
  }

  /**
   * Open a modal to start the csv site upload process
   */
  public openSiteSyncModal(selectedSitesList: SiteDTO[]) {
    const modalRef = this._syncModalService.openSiteSyncModal(selectedSitesList);
    modalRef.componentInstance.downloadCsvSites
      .pipe(
        switchMap(() => this.dataSource$.pipe(take(1))),
        takeUntil(modalRef.afterClosed)
      )
      .subscribe((dataSource) => {
        // export the list of sites selected as csv
        this._syncModalService.exportSites(!!selectedSitesList.length ? selectedSitesList : dataSource.rows);
      });
  }

  public updateSiteListSort({ sortBy }: IDataTableColumnSortEvent) {
    this._store.dispatch(SiteManagementAction.UpdateSiteListSort({ sortBy }));
  }

  /**
   * Allow to redirect to the board editor using site ID
   *
   * @param siteId Site id
   */
  public redirectToBoardEditor(siteId: number) {
    this.redirect([siteId]);
  }

  public redirectToGeoFixer() {
    this.redirect(['sites-fix']);
  }

  public onSelectionChanged(sites: SiteDTO[]) {
    this._store.dispatch(SiteManagementAction.UpdateSelectedSites({ selectedSiteIds: sites?.map((selectedSite) => selectedSite.id.toString()) }));
  }

  public onRowActived(site: SiteDTO) {
    const path = site ? getNewPathUrl(this.route.snapshot, site.id) : ['sites'];
    this.redirect([...path], { queryParamsHandling: 'preserve' });
  }

  public onExportSites(selectedSitesList: SiteDTO[]) {
    this.dataSource$.pipe(take(1), takeUntil(this._componentDestroyed$)).subscribe((dataSource) => {
      this._syncModalService.exportSites(!!selectedSitesList.length ? selectedSitesList : dataSource.rows);
    });
  }

  public openSettingsDrawer(): void {
    this._modalService.openDrawer<SiteManagementSettingsDrawerComponent, any>(SiteManagementSettingsDrawerComponent, { closeOnBackdropClick: true });
  }

  public onCloseSiteDetail(): void {
    this.redirectAndClearActiveRow(['sites'], { queryParamsHandling: 'preserve' });
  }

  /**
   * Add a color indicator to the front of the checkbox
   *
   * @param row
   */
  public addIndicatorClass(row: any): { [className: string]: boolean } {
    return { 'color-indicator': row.id === this.currentSiteId };
  }

  private redirect(subPath: Array<string | number>, extras?: NavigationExtras): Observable<boolean> {
    return from(this.router.navigate([...this._siteManagementConfig.moduleBasePath, ...subPath], extras)).pipe(take(1));
  }

  private redirectAndClearActiveRow(subPath: Array<string | number>, extras?: NavigationExtras) {
    this.redirect(subPath, extras)
      .pipe(
        filter((result: boolean) => !!result),
        tap(() => {
          this.dataTableComponent.clearActiveRow();
          this.dataTableComponent.clearSelectedRows();
        })
      )
      .subscribe();
  }

  private _addActionColumns(cols: IDataTableColumnConfig<void>[]): IDataTableColumnConfig<any>[] {
    const actionsCol = {
      id: 'SITE_LIST_ACTIONS',
      dataCellTemplate: this.siteListActionsTemplate,
      sortable: false,
      resizable: false,
      draggable: false,
      sticky: true,
      stickyPosition: 'right',
    } as IDataTableColumnConfig<any>;
    cols.push(actionsCol);

    return cols;
  }

  private getBreadcrumbs(site: SiteDTO) {
    {
      const breadcrumbs = [];
      const path = [];
      let activeRoute = this.route.snapshot.firstChild;

      while (activeRoute) {
        switch (activeRoute.routeConfig.path) {
          case 'tag-assignment':
            path.push(activeRoute.routeConfig.path);
            path.push(site.id.toString());
            breadcrumbs.push({ label: site.name, icon: 'social:location_city', path: [...path] });

            breadcrumbs.push({
              label: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.TAG_20'),
              icon: 'fa:tags',
              path: [...path],
            });

            break;
          case ':siteId':
            path.push(activeRoute.params.siteId);
            breadcrumbs.push({
              label: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.SITE_OVERVIEW_20'),
              icon: 'social:location_city',
              path: [...path],
            });
            break;
          case 'detail':
            path.push(activeRoute.routeConfig.path);
            breadcrumbs.push({
              label: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.DETAIL_20'),
              path: [...path],
            });
            break;
          case 'edit':
            path.push(activeRoute.routeConfig.path);
            breadcrumbs.push({
              label: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.EDIT_20'),
              path: [...path],
            });
            break;
          case 'board':
            path.push(activeRoute.routeConfig.path);
            breadcrumbs.push({
              label: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.BOARD_20'),
              path: [...path],
            });
            break;
          case ':boardOrgPath':
            path.push(activeRoute.params.boardOrgPath);
            breadcrumbs.push({ label: activeRoute.params.boardOrgPath, icon: 'content:web_stories', path: [...path] });
            break;
          case 'screen':
            path.push(activeRoute.routeConfig.path);
            breadcrumbs.push({
              label: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.SCREEN_20'),
              path: [...path],
            });
            break;
          case ':screenName':
            path.push(activeRoute.params.screenName);
            breadcrumbs.push({
              label: activeRoute.params.screenName,
              icon: 'communication: desktop_windows',
              path: [...path],
            });
            break;
          case 'tag':
            path.push(activeRoute.routeConfig.path);
            breadcrumbs.push({
              label: this.translateService.translate('siteManagementScope.SITE_MANAGEMENT.SITE_DETAIL.SIDE_MENU.LABEL.TAG_20'),
              icon: 'fa:tags',
              path: [...path],
            });
            break;
        }
        activeRoute = activeRoute.firstChild;
      }
      return breadcrumbs;
    }
  }
}
