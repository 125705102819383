import { IGridSize, IModalConfig, ModalRef, MODAL_CONFIG, IModalComponent, dataOnceReady, AsyncDataState } from '@activia/ngx-components';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { first, map, Observable, of, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { IModalPickerComponent } from '../../../../models/modal-picker-component.interface';
import * as BoardSelectors from '../../../../store/board/board.selectors';
import { IBoard } from '../../../../models/board-config.interface';
import { getBoardOrgPathFromTags, IOrgPathDefNode, IOrgPathDefRoot, selectBoardOrgPathDefinition, selectBoardOrgPathDefinitionState, selectBoardTagKeysSchema } from '@amp/tag-operation';
import { IProperties } from '@activia/json-schema-forms';
import { BoardTagsService } from '@activia/cm-api';

@Component({
  selector: 'amp-site-management-board-editor-modal',
  templateUrl: './site-management-board-editor-modal.component.html',
  styleUrls: ['./site-management-board-editor-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementBoardEditorModalComponent implements IModalComponent, IModalPickerComponent<Partial<IBoard>> {
  @Output() saved = new EventEmitter<{ tags: Record<string, any>; size: IGridSize }>();
  @Output() cancel = new EventEmitter();

  isConnectedElementOpened = false;
  boardFormGroup: UntypedFormGroup;

  minSelectedGridSize: IGridSize = { row: 1, column: 1 };

  nameUniquenessValidator = (control: AbstractControl): Observable<{ [key: string]: boolean } | null> =>
    this._store.select(BoardSelectors.selectBoardByOrgPath).pipe(
      first(),
      map((boards) => {
        const orgPathControl = control.get('boardOrgPath');
        const nameControl = control.get('boardName');

        if (orgPathControl.pristine && nameControl.pristine) {
          return null;
        }

        // If orgpath and name have not changed
        if (
          (!!this.dialogConfig.data?.organizationPath &&
            this.dialogConfig.data?.organizationPath === orgPathControl.value &&
            !!this.dialogConfig.data?.name &&
            this.dialogConfig.data?.name === nameControl.value) ||
          !orgPathControl.value ||
          !nameControl.value
        ) {
          return null;
        }

        // Check if board orgpath + name is unique
        const isUnique = !boards[orgPathControl.value]?.some((e) => e.name === nameControl.value);
        return isUnique ? null : { duplicate: true };
      })
    );

  boardOrgPathDef$: Observable<IOrgPathDefRoot>;
  boardOrgPathDefState$: Observable<AsyncDataState>;

  tagDefinition$: Observable<Record<string, IProperties>>;

  /** Default value of the dependent schema for the orgPath and the name */
  initialOrgPathValue$: Observable<Record<string, unknown>>;

  /** Tag/name value from the board org path form */
  formValue: Record<string, unknown>;

  constructor(
    private _fb: UntypedFormBuilder,
    private _store: Store,
    private _dialogRef: ModalRef<SiteManagementBoardEditorModalComponent>,
    private boardTagService: BoardTagsService,
    @Inject(MODAL_CONFIG)
    public dialogConfig: IModalConfig<Partial<IBoard>>
  ) {
    this.boardOrgPathDef$ = dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState));
    this.boardOrgPathDefState$ = this._store.select(selectBoardOrgPathDefinitionState);

    this.tagDefinition$ = this._store.select(selectBoardTagKeysSchema);

    this.boardFormGroup = this._fb.group(
      {
        orgPathArray: this._fb.array([]),
        boardOrgPath: new UntypedFormControl(this.dialogConfig.data?.organizationPath),
        boardName: new UntypedFormControl(this.dialogConfig.data?.name, [Validators.required]),
        boardSize: new UntypedFormControl(this.dialogConfig.data?.size ?? { row: 1, column: 1 }, [Validators.required]),
      },
      {
        asyncValidators: (control) => this.nameUniquenessValidator(control),
      }
    );

    if (this.dialogConfig.data) {
      this.minSelectedGridSize = this.dialogConfig.data?.minSelectableSize;
    }

    this.initialOrgPathValue$ = this.dialogConfig.data?.id
      ? this._store.select(selectBoardTagKeysSchema).pipe(
          map((tagKeys) => Object.keys(tagKeys)),
          switchMap((takKeys) => this.boardTagService.findTagsForEntity(this.dialogConfig.data.id, takKeys)),
          //sanitize value from string[] => string
          map((tagValues) => Object.keys(tagValues).reduce((acc, curr) => ({ ...acc, [curr]: tagValues[curr][0] }), { name: this.dialogConfig.data?.name }))
        )
      : of({ name: this.dialogConfig.data?.name });
  }

  hasNameProperty(schema: IOrgPathDefNode) {
    if (schema.property === 'name') {
      return true;
    } else {
      return !!schema.childOneOf?.some((e) => this.hasNameProperty(e));
    }
  }

  canClose(): boolean {
    return true;
  }

  onClose() {
    this._dialogRef.close();
  }

  onCancel() {
    this.onClose();
    this.cancel.emit();
  }

  onUpdateBoard() {
    this.saved.emit({ tags: this.formValue, size: this.boardFormGroup.value.boardSize });
    this.onClose();
  }

  openGridSizeSelector() {
    if (!this.isBoardLocked()) {
      this.isConnectedElementOpened = !this.isConnectedElementOpened;
    }
  }

  onClickOutside() {
    this.isConnectedElementOpened = !this.isConnectedElementOpened;
    this.boardFormGroup.get('boardSize').markAsTouched();
    this.boardFormGroup.get('boardSize').setErrors(this.boardFormGroup.get('boardSize').value ? null : { required: true });
  }

  onGridSizeSelected(event: IGridSize) {
    this.boardFormGroup.get('boardSize').patchValue({ row: event.row, column: event.column });
    this.isConnectedElementOpened = !this.isConnectedElementOpened;
    this.boardFormGroup.get('boardSize').markAsTouched();
    this.boardFormGroup.get('boardSize').setErrors(null);
  }

  isBoardLocked(): boolean {
    return !!this.dialogConfig.data?.isLocked;
  }

  getDependentItem(item: Record<string, unknown>) {
    if (item.error) {
      this.formValue = {};
      return;
    }

    this.boardOrgPathDef$.pipe(first()).subscribe((boardOrgPathDef) => {
      const orgPath = getBoardOrgPathFromTags(boardOrgPathDef, item);
      if (item.name) {
        this.boardFormGroup.get('boardName').markAsDirty();
        this.boardFormGroup.get('boardName').patchValue(item.name);
      }

      this.boardFormGroup.get('boardOrgPath').markAsDirty();
      this.boardFormGroup.get('boardOrgPath').patchValue(orgPath);
    });

    this.formValue = item;
  }
}
