import { Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { concatMap, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, EMPTY, forkJoin, Observable, pipe } from 'rxjs';
import { BoardTagsService, ScreenTagsService, SiteTagsService, TagKeyDescDTO, TagOperationDTO, TagOperationResult } from '@activia/cm-api';

import { AsyncDataState, dataOnceReady, ErrorState, LoadingState } from '@activia/ngx-components';
import { MessengerNotificationService } from '@amp/messenger';
import { Board, ITagValue, TagValueAssignmentScope } from '../../model/tag-value.interface';
import { EngineTagLevel, INTERNAL_APP } from '../../model/operation-scope.interface';
import { IEngineTagKey } from '../../model/engine-tag-key.interface';
import { endWithDotJson } from '../../utils/schema-helper';
import { HttpErrorResponse } from '@angular/common/http';
import { convertToEngineTagKey, sanitizeOutgoingTagValues } from '@amp/tag-operation';

export interface IEngineTagValueDetailState {
  /** Records of tag values, key is the combination of key-level */
  tags: Record<string, ITagValue>;
  /** Records of tag [values, key] in a temporary state of uncommitted tags */
  editedTags: Record<string, ITagValue>;
  /** tag loading state */
  loadingState: AsyncDataState;
  /** Flag when updating tags */
  updatingState: AsyncDataState;
  /** current entity id */
  id: number;
  /** current entity ids */
  ids: number[];
  /** board screen index when working on a screen */
  idx: number;
  /** entity that has tags */
  level: EngineTagLevel;
  boards: Board[];
  entityName: string;
}

export const DEFAULT_ENGINE_TAG_VALUE_DETAIL_STATE: IEngineTagValueDetailState = {
  tags: {},
  editedTags: {},
  loadingState: LoadingState.INIT,
  updatingState: LoadingState.INIT,
  id: undefined,
  ids: [],
  idx: undefined,
  level: undefined,
  boards: [],
  entityName: undefined,
};

@Injectable()
export class EngineTagValueDetailStore extends ComponentStore<IEngineTagValueDetailState> {
  /** The list of engine tag keys for dropdown */
  engineTagKeys$: Observable<IEngineTagKey[]> = EMPTY;
  /********************************************************************************************************************
   * SELECTORS and GETTERS
   ********************************************************************************************************************/
  pristineTags$ = this.select((state) => Object.values(state.tags));
  editedTagsRecord$ = this.select((state) => state.editedTags);
  tagState$ = this.select((state) => state.loadingState);
  updatingState$ = this.select((state) => state.updatingState);
  isLoading$ = this.select((state) => state.loadingState === LoadingState.LOADING);
  appOwnerFromEditedTags$ = this.select((state) => [...new Set(Object.values(state.editedTags).map((tag) => (tag.keyDescription as TagKeyDescDTO)?.owner?.toUpperCase() || INTERNAL_APP))]);
  appOwners$ = dataOnceReady(this.pristineTags$, this.tagState$).pipe(map((tags) => [...new Set(tags.map((tag) => (tag.keyDescription as TagKeyDescDTO)?.owner?.toUpperCase() || INTERNAL_APP))]));
  /********************************************************************************************************************
   * UPDATERS (aka. reducers)
   ********************************************************************************************************************/
  setTags = this.updater(
    (state: IEngineTagValueDetailState, payload: { tags?: Record<string, ITagValue>; id?: number; ids?: number[]; level?: EngineTagLevel; idx?: number; boards?: Board[]; entityName?: string }) => ({
      ...state,
      tags: payload.tags || {},
      id: payload.id !== undefined ? payload.id : state.id,
      idx: payload.idx !== undefined ? payload.idx : state.idx,
      level: payload.level || state.level,
      boards: payload.boards || state.boards,
      entityName: payload.entityName || state.entityName,
    })
  );
  deleteTag = this.updater((state: IEngineTagValueDetailState, key: string) => {
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    const { [key]: removed, ...remainingTags } = state.tags;
    return {
      ...state,
      tags: { ...remainingTags },
    };
  });

  updateSelectedIds = this.updater((state: IEngineTagValueDetailState, ids: number[]) => ({ ...state, ids }));

  updateTags = this.updater((state: IEngineTagValueDetailState, uTags: Record<string, ITagValue>) => ({
    ...state,
    tags: { ...state.tags, ...uTags },
    loadingState: LoadingState.LOADED,
    editedTags: {},
  }));

  resetEditedTags = this.updater((state: IEngineTagValueDetailState) => ({
    ...state,
    editedTags: {},
  }));

  updateEditedTags = this.updater((state: IEngineTagValueDetailState, uTags: Record<string, ITagValue>) => {
    const previousEditedTags = { ...state.editedTags };

    Object.values(uTags).forEach((tag) => {
      const tagKey = `${tag.key}-${tag.propertyType}`;
      //If tags are not saved, there is no reason to pursue with a delete operation or if tags already exist in a committed state,don't add
      if ((tag.operation === 'delete' && !state.tags[tagKey]) || (tag.operation === 'add' && state.tags[tagKey])) {
        delete previousEditedTags[tagKey];
        delete uTags[tagKey];
      }
    });

    return {
      ...state,
      editedTags: { ...previousEditedTags, ...uTags },
    };
  });

  changeLoadingState = this.updater((state: IEngineTagValueDetailState, loadingState: AsyncDataState) => ({
    ...state,
    loadingState,
  }));

  changeUpdatingState = this.updater((state: IEngineTagValueDetailState, updatingState: AsyncDataState) => ({
    ...state,
    updatingState,
  }));

  /********************************************************************************************************************
   * EFFECTS
   ********************************************************************************************************************/
  /** Assign custom observable when store is ready */
  whenStoreReady$ = this.effect(() =>
    this.state$.pipe(
      filter((value) => value !== null && value !== undefined),
      take(1),
      tap(() => {
        this.engineTagKeys$ = forkJoin([
          this._siteTagsService.findAllTagKeys2().pipe(map((res) => convertToEngineTagKey(res, EngineTagLevel.SITE))),
          this._boardTagsService.findAllTagKeys().pipe(map((res) => convertToEngineTagKey(res, EngineTagLevel.BOARD))),
          this._screenTagsService.findAllTagKeys1().pipe(map((res) => convertToEngineTagKey(res, EngineTagLevel.SCREEN))),
        ]).pipe(
          map(([siteTags, boardTags, screenTags]) => [...siteTags, ...boardTags, ...screenTags]),
          shareReplay({ refCount: true, bufferSize: 1 })
        );
      })
    )
  );
  /** get tags of all levels **/
  getTags = this.effect<TagValueAssignmentScope>((scope$) =>
    scope$.pipe(
      tap((scope) =>
        this.setTags({
          id: scope.id,
          ids: scope.ids,
          level: scope.level,
          idx: scope.idx,
          boards: scope.boards,
          entityName: scope.entityName,
        })
      ),
      tap(() => this.changeLoadingState(LoadingState.LOADING)),
      switchMap((scope) =>
        forkJoin([this.getTagsRequest(scope), this.engineTagKeys$.pipe(map((keys) => keys.filter((key) => key.level === scope.level)))]).pipe(
          map(([tags, tagKeys]) =>
            // For each tag key
            Object.entries(tags || {}).map(([key, values]) => {
              const description = tagKeys?.find((tagKey) => tagKey.key === key)?.description;
              return {
                key,
                propertyType: scope.level,
                values: this._tagValueAPIToUI(key, values),
                keyDescription: description,
              } as ITagValue;
            })
          ),
          map((tags) =>
            tags.reduce(
              (acc, curr) => ({
                ...acc,
                [`${curr.key}-${curr.propertyType}`]: curr,
              }),
              {} as { [key: string]: ITagValue }
            )
          ),
          tapResponse<Record<string, ITagValue>>(
            (tags) => {
              this.setTags({ tags });
              this.changeLoadingState(LoadingState.LOADED);
            },
            (errResponse: HttpErrorResponse) => {
              this.changeLoadingState({ errorMsg: errResponse.error.message } as ErrorState);
              this.notificationService.showErrorMessage('tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.MESSAGE.FETCH_ERROR_100', { msg: errResponse?.error?.message });
            }
          )
        )
      )
    )
  );

  /** Update tag values */
  applyTagsChange = this.effect<void>(
    pipe(
      withLatestFrom(this.editedTagsRecord$),
      concatMap(([, eTags]: [void, Record<string, ITagValue>]) => {
        const sanitizedTagValues: Record<string, ITagValue> = sanitizeOutgoingTagValues(eTags);
        const { operations, request$ } = this.getEditTagsRequest(sanitizedTagValues);
        return request$.pipe(
          tapResponse<Array<TagOperationResult>>(
            (res) => {
              const errorKeys = res.map((result, index) => (result.hasOwnProperty('error') ? operations[index].key : undefined)).filter((errorKey) => !!errorKey) || [];
              let updatedTags = {};
              let addedTags = {};
              Object.values(eTags).forEach((tag: ITagValue) => {
                const key = `${tag.key}-${tag.propertyType}`;
                if (errorKeys.includes(tag.key)) {
                  delete eTags[key];
                  return;
                }
                if (!tag.values || tag.values.length === 0) {
                  this.deleteTag(key);
                  delete eTags[key];
                }
                switch (tag.operation) {
                  case 'delete':
                    this.deleteTag(key);
                    break;
                  case 'replace':
                    updatedTags = { ...updatedTags, [key]: tag };
                    break;
                  case 'add':
                    // remove 'add' operation from tag, this allows further editing of
                    // the same new inputs in the same session: fix for CMUI-4351 & CMUI-4347
                    delete tag.operation;
                    addedTags = { ...addedTags, [key]: tag };
                    break;
                }
              });
              this.updateTags({ ...updatedTags, ...addedTags });
              this.changeUpdatingState(LoadingState.LOADED);
              if (errorKeys.length > 0) {
                this.notificationService.showErrorMessage('tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.MESSAGE.UPDATE_MULTIPLE_ERROR_50', { keys: errorKeys.join(',') });
              } else {
                this.notificationService.showSuccessMessage('tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.MESSAGE.UPDATE_MULTIPLE_SUCCESS_50');
              }
            },
            (errResponse: HttpErrorResponse) => {
              this.changeUpdatingState({ errorMsg: errResponse.error.message } as ErrorState);
              this.notificationService.showErrorMessage('tagOperation.TAG_OPERATION.TAG_VALUE_DETAIL.MESSAGE.UPDATE_MULTIPLE_EXCEPTION_50', { msg: errResponse?.error?.message });
            }
          )
        );
      })
    )
  );

  constructor(
    private _siteTagsService: SiteTagsService,
    private _boardTagsService: BoardTagsService,
    private _screenTagsService: ScreenTagsService,
    private notificationService: MessengerNotificationService
  ) {
    super(DEFAULT_ENGINE_TAG_VALUE_DETAIL_STATE);
  }

  private getTagsRequest(scope: TagValueAssignmentScope) {
    let request$: Observable<{ [key: string]: object[] }>;
    switch (scope.level) {
      case EngineTagLevel.SITE:
        request$ = this._siteTagsService.findTagsForEntity2(scope.id);
        break;
      case EngineTagLevel.BOARD:
        request$ = this._boardTagsService.findTagsForEntity(scope.id);
        break;
      case EngineTagLevel.SCREEN:
        request$ = this._screenTagsService.findTagsForEntity1(scope.id, scope.idx);
        break;
      default:
        request$ = EMPTY;
        break;
    }
    return request$;
  }

  private getEditTagsRequest(eTags: Record<string, ITagValue>) {
    const operations: Array<TagOperationDTO> = this.convertTagChangeToOperations(Object.values(eTags));
    const isBulk = this.get().ids.length > 1;
    let request$;
    switch (this.get().level) {
      case EngineTagLevel.SITE:
        request$ = isBulk
          ? this._siteTagsService.patchBulkTagsForEntities2({
              ids: this.get().ids,
              operations,
            })
          : this._siteTagsService.patchTagsForEntity2(this.get().id, operations);
        break;
      case EngineTagLevel.BOARD:
        request$ = isBulk
          ? this._boardTagsService.patchBulkTagsForEntities({
              ids: this.get().ids,
              operations,
            })
          : this._boardTagsService.patchTagsForEntity(this.get().id, operations);
        break;
      case EngineTagLevel.SCREEN:
        // todo convert to patch bulk
        request$ = request$ = isBulk
          ? combineLatest(this.getAssignTagsToScreen(Object.values(eTags), this.get().boards))
          : this._screenTagsService.patchTagsForEntity1(this.get().id, this.get().idx, operations);
        break;
      default:
        request$ = EMPTY;
        break;
    }
    return { operations, request$ };
  }

  private getAssignTagsToScreen(tags: ITagValue[], boards: Board[]): Observable<TagOperationResult[]> {
    const operations = this.convertTagChangeToOperations(Object.values(tags));
    const screens = boards.map((board) => board.screens).flat();
    return this._screenTagsService.patchTagsForEntities1(
      screens.map((item) => item.name),
      screens.map((item) => item.boardScreenIdx),
      boards.map((item) => item.name),
      boards.map((item) => item.id),
      operations
    );
  }

  /** Convert tag chagnes into patch operations */
  private convertTagChangeToOperations(eTags: ITagValue[]) {
    let res = [];
    eTags.forEach((tag) => {
      // api require either json object or string as value
      const newValues = this._tagValueUIToAPI(tag);
      const op = tag.operation;
      switch (tag.operation) {
        case 'replace':
          res = [...res, { op: 'replace', key: tag.key, newValues }];
          break;
        case 'add':
          res = [...res, { op, key: tag.key, newValues }];
          break;
        case 'delete':
          res = [...res, { op, key: tag.key }];
          break;
      }
    });
    return res;
  }

  /** UI widget handle stringified json */
  private _tagValueAPIToUI(key: string, tagValue: object[]): string[] {
    return tagValue.map((value) => (endWithDotJson(key) ? JSON.stringify(value) : value.toString()));
  }

  /** api require tag value to be string or json obj */
  private _tagValueUIToAPI(tagValue: ITagValue): any {
    const { key, values } = tagValue;
    const isJson = endWithDotJson(key);

    if (values.length === 1 && typeof values[0] === 'string') {
      return isJson ? [JSON.parse(values[0])] : [values[0]];
    }
    return values.map((value) => (isJson ? JSON.parse(value) : value.toString()));
  }
}
