import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import * as SiteManagementAction from '../store/site-management.actions';
import { Store } from '@ngrx/store';
import { ISiteManagementState } from '../store/site-management.reducer';

/** Fetch current site with devices and boards */
@Injectable()
export class PreloadSiteDetailGuard implements CanActivate {
  constructor(private _store: Store<ISiteManagementState>) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const siteId = +route.params.siteId;
    this._store.dispatch(SiteManagementAction.FetchSiteDetail({ siteId }));

    return true;
  }
}
