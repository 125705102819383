import { TagKeyDescDTO } from '@activia/cm-api';
import { IProperties, JsonSchemaFormFactoryService } from '@activia/json-schema-forms';
import { ThemeType } from '@activia/ngx-components';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ITagValue } from '../../../model/tag-value.interface';
import { IJsonSchemaWrapper } from '../../../model/tag-values-operations.interface';
import { getJsonSchema } from '../../../utils/schema-helper';
import { sanitizeTagValues } from '../../../utils/tag.util';
import { isEqual } from 'lodash';

@Component({
  selector: 'amp-tag-value-item',
  templateUrl: './tag-value-item.component.html',
  styleUrls: ['./tag-value-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagValueItemComponent {
  @ViewChild('editOrigin', { static: false }) editOrigin: CdkOverlayOrigin;

  @Input() set tag(tag: ITagValue) {
    if (!isEqual(tag, this._tag)) {
      this.setTagInfo(tag);
    }
  }

  @Input() ownerConfig: { name: string; color: string; external: boolean };
  @Input() isDeletable = true;

  @Output() selectedOwnerActioned: EventEmitter<string> = new EventEmitter<string>();
  @Output() deleted: EventEmitter<ITagValue> = new EventEmitter();
  /** value changed */
  @Output() changed: EventEmitter<ITagValue> = new EventEmitter();

  _tag: ITagValue;
  /** Schema, formGroup and properties to use JsonSchemaComponent  */
  currentSchema: IJsonSchemaWrapper;
  /** tag values to be edited used by form */
  formValues: Record<string, unknown> = {};
  /** Show editor widget  */
  showForm = false;
  /** Trace if tag value has been changed, compared to initialValues */
  @Input() isEdited = false;
  themeType = ThemeType;

  constructor(private _jsonSchemaFactoryService: JsonSchemaFormFactoryService) {}

  onDeleteTag() {
    this.deleted.emit(this._tag);
  }

  applyTagChanges(form: UntypedFormGroup) {
    const formObject = form.getRawValue();
    for (const key in formObject) {
      if (this._tag.key === key) {
        let values = formObject[key];
        this.formValues[this._tag.key] = values;
        this.showForm = false;
        if (!this._tag.keyDescription.multivalues) {
          // Tag value always come from the backend in an Array, even if the tag isn't multivalues
          // We have to sanitize the value back for backend in this specific case
          values = values === undefined || values === null || values === '' ? [] : ['' + values];
        }

        this.changed.emit({
          key: this._tag.key,
          values,
          propertyType: this._tag.propertyType,
          keyDescription: this._tag.keyDescription,
        });
      }
    }
  }

  exampleExist(examples: string[]) {
    return (examples || [])?.filter((example) => !!example).length > 0;
  }

  infoExist(schema: IProperties): boolean {
    return !schema ? false : schema.description?.length > 0 || schema.default?.length > 0 || this.exampleExist(schema['examples']);
  }

  getLabel(tag: any) {
    const schemaTitle = tag?.keyDescription?.schema?.title;
    const key = tag?.key;
    return schemaTitle && key ? `${schemaTitle} | ${key}` : schemaTitle || key || '';
  }

  private setTagInfo(tag: ITagValue) {
    this._tag = tag;
    const { key, keyDescription } = tag;
    this.currentSchema = getJsonSchema(key, keyDescription, this._jsonSchemaFactoryService);
    // Tag value always come from the backend in an Array, even if the tag isn't multivalues
    // We have to sanitize the value in this specific case
    this.formValues = sanitizeTagValues({ [key]: Array.isArray(tag.values) ? tag.values : [tag.values] }, { [key]: keyDescription as TagKeyDescDTO });
  }
}
