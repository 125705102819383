import { DeviceHealthCountDTO, DeviceService } from '@activia/cm-api';
import { AuthExpired, Logout, LogoutFromTabSync } from '@amp/auth';
import { GlobalFacade } from '@amp/global';
import { TimerService } from '@amp/messenger';
import { Injectable } from '@angular/core';
import { ScannedActionsSubject } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ErrorHandlingService } from '@amp/error';

export interface IDeviceHealthStatus {
  deviceGroupId: number;
  moduleName: string;
  statusSubject: BehaviorSubject<DeviceHealthCountDTO>;
  latestStatus: DeviceHealthCountDTO;
  count: DeviceHealthCountDTO;
}

@Injectable({ providedIn: 'root' })
export class DevicesStatusService {
  // Refresh counts of all device groups in ms
  public static refreshDelay = 60000;

  private timerSubscription: any;
  private combineLatestSubscription: Subscription;

  // Count of total number of subscribers (number of components listening to the count changes) in
  // the app
  private subscriberCount = 0;

  /**
   * Default device group for the app. This is used for quick search in the top nav bar. Device
   * counts in the side nav and dashboard also use this app-level default device group.
   *
   * The sequence to determine which device group is the default is as follow:
   * 1. If there is a default device group saved in the user preference, this device group is used.
   * 2. If user is authorized All Device Groups, All Device Group will be the default.
   * 3. If user has at least one scope, the first device group in the first scope is used.
   */
  private defaultDeviceGroupKey = 'App';
  private defaultDeviceGroupId = 1;
  private defaultCount: DeviceHealthCountDTO = { ok: 0, warning: 0, error: 0, unreachable: 0, notMonitored: 0 };

  /**
   * Key: module name (e.g. "App", "Monitoring", etc)
   * Note that these 2 maps reference to the same IDeviceHealthStatus value
   */
  private moduleDeviceGroupsMap = new Map<string, IDeviceHealthStatus>();

  /**
   * Key: device group ID
   * Note that these 2 maps reference to the same IDeviceHealthStatus value.
   * This map is used by the timer to pull data from the backend. Since it contains unique
   * device group IDs, there will be no duplicate call for the same ID.
   * Without this map, every time the timer is triggered (every 10 seconds), we will
   * have to filter out unique device group IDs from moduleDeviceGroupsMap to avoid
   * duplicate API calls.
   */
  private deviceGroupsMap = new Map<number, { subscribers: Array<IDeviceHealthStatus>; pending?: boolean }>();

  constructor(
    private devicesService: DeviceService,
    private globalFacade: GlobalFacade,
    private errorHandlingService: ErrorHandlingService,
    private timerService: TimerService,
    actionsSubject: ScannedActionsSubject
  ) {
    actionsSubject.pipe(filter((a) => a.type === Logout.type || a.type === AuthExpired.type || a.type === LogoutFromTabSync.type)).subscribe(() => {
      this.terminate();
    });
  }

  getDevicesStatus(moduleName?: string): Observable<DeviceHealthCountDTO> {
    this.checkTimer();
    return this.moduleDeviceGroupsMap.get(moduleName ? moduleName : this.defaultDeviceGroupKey).statusSubject.asObservable();
  }

  /**
   * Important! If a component wants to track device health count of a device group, call this
   * function first to register itself as a subscriber.
   */
  start(moduleName?: string, deviceGroupId?: number): void {
    if (this.subscriberCount <= 0) {
      this.setDefaultDevice();
    }
    if (moduleName && deviceGroupId) {
      this.addMapElement(moduleName, deviceGroupId);
    }
    this.subscriberCount++;
    this.checkTimer();
  }

  /**
   * Note that some modules can have their own "default device group".
   * This function updates the default device group of a module.
   */
  updateDeviceGroup(moduleName: string, newDeviceGroupId: number): void {
    this.updateMapElement(moduleName, newDeviceGroupId);
  }

  /**
   * Important! Call this function when a subscriber component destroys to update subscriber count
   * and the map.
   */
  stop(moduleName?: string): void {
    if (this.subscriberCount > 0) {
      this.subscriberCount--;
    }

    if (moduleName) {
      this.deleteMapElement(moduleName);
    }

    if (this.subscriberCount <= 0) {
      this.terminate();
    }
  }

  /**
   * This function terminates the timer that periodically pulls data from the backend.
   * This is called when:
   * 1. User logs out or session time out
   * 2. No more subscriber component
   * 3. Fail to pull data from the backend
   */
  terminate(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = undefined;
    }
    if (this.combineLatestSubscription) {
      this.combineLatestSubscription.unsubscribe();
      this.combineLatestSubscription = undefined;
    }
    this.subscriberCount = 0;
    this.moduleDeviceGroupsMap.clear();
    this.deviceGroupsMap.clear();
  }

  /**
   * Set the app-level default device group to track
   */
  private setDefaultDevice(): void {
    this.addMapElement(this.defaultDeviceGroupKey, this.defaultDeviceGroupId);
    this.combineLatestSubscription = this.globalFacade.defaultDeviceGroup$.subscribe((defaultDeviceGroup) => {
      if (defaultDeviceGroup) {
        // If there is a saved default device group in user preferences, take that one
        this.updateMapElement(this.defaultDeviceGroupKey, defaultDeviceGroup.id);
      }
    });
  }

  /**
   * If any module has its own "default device group", add this value into the map.
   */
  private addMapElement(moduleName: string, deviceGroupId: number): void {
    if (!this.moduleDeviceGroupsMap.has(moduleName)) {
      const data = {
        deviceGroupId,
        moduleName,
        statusSubject: new BehaviorSubject<DeviceHealthCountDTO>({ ...this.defaultCount }),
        latestStatus: { ...this.defaultCount },
        count: { ...this.defaultCount },
      };
      this.moduleDeviceGroupsMap.set(moduleName, data);

      // Add this deviceGroupId to the other map if it's not in the other map yet
      this.addDeviceGroupsMapElement(deviceGroupId, data);
    }
  }

  /**
   * Update the default device group of a module
   */
  private updateMapElement(moduleName: string, deviceGroupId: number): void {
    const value = this.moduleDeviceGroupsMap.get(moduleName);
    if (value) {
      const dataInModuleMap = this.moduleDeviceGroupsMap.get(moduleName);
      const oldDeviceGroupId = dataInModuleMap.deviceGroupId;
      dataInModuleMap.deviceGroupId = deviceGroupId;

      if (oldDeviceGroupId !== deviceGroupId) {
        this.deleteDeviceGroupsMapElement(oldDeviceGroupId, moduleName);
        this.addDeviceGroupsMapElement(deviceGroupId, dataInModuleMap);
      }
    } else {
      this.addMapElement(moduleName, deviceGroupId);
    }
  }

  /**
   * Delete the default device group of a module
   */
  private deleteMapElement(moduleName: string) {
    const data = this.moduleDeviceGroupsMap.get(moduleName);
    if (data) {
      const deviceGroupId = data.deviceGroupId;
      this.moduleDeviceGroupsMap.delete(moduleName);
      this.deleteDeviceGroupsMapElement(deviceGroupId, moduleName);
    }
  }

  private addDeviceGroupsMapElement(deviceGroupId: number, data: IDeviceHealthStatus) {
    if (this.deviceGroupsMap.has(deviceGroupId)) {
      const mapData = this.deviceGroupsMap.get(deviceGroupId);
      mapData.subscribers.push(data);
    } else {
      this.deviceGroupsMap.set(deviceGroupId, { subscribers: [data] });
    }
  }

  private deleteDeviceGroupsMapElement(deviceGroupId: number, moduleName: string) {
    if (this.deviceGroupsMap.has(deviceGroupId)) {
      const mapData = this.deviceGroupsMap.get(deviceGroupId);
      const newSubscribers = mapData.subscribers.filter((s) => s.moduleName !== moduleName);
      if (newSubscribers.length <= 0) {
        this.deviceGroupsMap.delete(deviceGroupId);
      } else {
        this.deviceGroupsMap.set(deviceGroupId, { subscribers: newSubscribers });
      }
    }
  }

  private checkTimer() {
    if (!this.timerSubscription) {
      const timer = this.timerService.timer$(DevicesStatusService.refreshDelay);
      this.timerSubscription = timer.subscribe((_) => this.refresh());
    }
  }

  refresh() {
    // Use deviceGroupsMap as this contains unique device group IDs
    if (this.deviceGroupsMap.size > 0) {
      this.deviceGroupsMap.forEach((value: { subscribers: Array<IDeviceHealthStatus>; pending?: boolean }, key: number) => {
        if (!value.pending) {
          value.pending = true;
          this.devicesService.getHealthStatusCountForDeviceGroup(key).subscribe(
            (_count) => {
              value.pending = false;

              // Since both maps reference to the same IDeviceHealthStatus value, emitting the
              // latest count from deviceGroupsMap will ensure that all subscriber components
              // receive the latest count
              value.subscribers.forEach((datum) => {
                datum.count = _count;
                datum.statusSubject.next(_count);
                datum.latestStatus = _count;
              });
            },
            (err) => {
              if (err.status !== 0) {
                this.errorHandlingService.catchError(err, undefined, 'global.error.fetch-device-group-health-status-fail', { key });
              }
              this.terminate();
            }
          );
        }
      });
    }
  }
}
