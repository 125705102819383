import { IBadgeSize, ModalRef, ModalService, ThemeType } from '@activia/ngx-components';
import { AssetTagKeyDescDTO } from '@amp/cms-api';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { IInvalidTagImpact, InvalidTagImpactFn, ITagChangeSummary, ITagOperationChange } from '../../model/tag-values-operations.interface';
import { TagKeyDescDTO } from '@activia/cm-api';
import { IEngineTagKey } from '../../model/engine-tag-key.interface';
import { IAssetTagKey } from '../../model/asset-tag-key.interface';
import { INTERNAL_APP, PropertyType } from '../../model/operation-scope.interface';
import { InvalidTagDialogComponent } from '../invalid-tag-dialog/invalid-tag-dialog.component';
import { ableToAssignMultiValue, adjustTagKeyCustomRequirement, ConstraintTypeEnum, ConstraintTypes, hasEnumSchema } from '../../utils/schema-helper';
import { ConstraintTypeEnum as ConstraintEnumType, IJsonConstraint } from '@activia/json-schema-forms';
import { findDetrimentalEnumConstraintChange } from '../../utils/tag.util';

@Component({
  selector: 'amp-tag-key-detail',
  templateUrl: './tag-key-detail.component.html',
  styleUrls: ['./tag-key-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagKeyDetailComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  /** Current editing tag */
  @Input() tag: IEngineTagKey | IAssetTagKey;

  /** Custom function to get back the edited tag impact */
  @Input() getInvalidTagsFn: InvalidTagImpactFn;

  @Output() editTag = new EventEmitter<{ key: string; description: TagKeyDescDTO | AssetTagKeyDescDTO; operations: ITagOperationChange }>();

  /** Emits close event */
  @Output() closed = new EventEmitter<void>();

  schemaTypeOptions: ConstraintTypeEnum[];

  /** the type of schema determined by constraint-component */
  detectedType: ConstraintTypeEnum;

  /** The schema type passed to constraint component for editing */
  typeAsConstraint: ConstraintTypeEnum;

  /** The current schema passed to constraint component for editing */
  schemaAsConstraint: IJsonConstraint;

  /** Check if the edit in constraint component is valid */
  constraintValid = false;

  propertyType: PropertyType;

  /** Form group for the AssetTagKeyDescDTO */
  tagForm: FormGroup;

  isValidatingTag$ = new BehaviorSubject<boolean>(false);

  /**
   * Mainly for enum constraint. True if user makes changes that are potentially detrimental or have unexpected
   * result.
   */
  detrimentalValues$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  /** Warning message when the new schema invalidate some assets */
  assetWarningMessage$ = new BehaviorSubject<ITagChangeSummary[]>([]);

  /** If it is enum tag */
  isEnumTag = false;

  /** If the tag owner is external */
  externalOwner: string;

  IBadgeSize = IBadgeSize;

  ThemeType = ThemeType;

  // @ts-ignore used in template
  ableToAssignMultiValue = ableToAssignMultiValue;

  private _tagValuesOperations: ITagOperationChange;

  private _componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private fb: UntypedFormBuilder, private _modalService: ModalService, private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.tagForm = this.fb.group({
      multivalues: false,
      private: false,
      dynamic: false,
      schema: this.fb.group({
        title: '',
        examples: this.fb.array(['']),
        boolean: false,
        type: '',
        default: '',
        description: '',
        enum: [],
        pattern: '',
        minLength: '',
        maxLength: '',
        minimum: undefined,
        maximum: undefined,
      }),
      advancedJson: undefined,
    });
  }

  ngAfterViewInit(): void {
    this.tagForm.patchValue({
      multivalues: this.tag.description.multivalues,
      private: this.propertyType === 'asset' ? (this.tag.description as AssetTagKeyDescDTO)._private : this.tagForm.get('private').value,
      dynamic: this.propertyType === 'asset' ? undefined : (this.tag?.description as TagKeyDescDTO).dynamic,
      schema: this.tag.description?.schema,
    });
    this.tagForm.markAsPristine();
    this._cdr.detectChanges();

    this.tagForm.valueChanges
      .pipe(
        debounceTime(500),
        filter(() => this.tagForm.dirty && this.detrimentalValues$.value.length === 0),
        map(() => this.getTagKeyDescription()),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(() => this.isValidatingTag$.next(true)), // Validation is in progress
        switchMap((newDesc) => this.getInvalidTagsFn.apply(null, [this.tag, this.isEnumTag, newDesc, this._tagValuesOperations])),
        takeUntil(this._componentDestroyed$)
      )
      .subscribe((result: IInvalidTagImpact[]) => {
        this.assetWarningMessage$.next(result); // Only show operations that will invalidate some assets
        this.isValidatingTag$.next(false);
      });
  }

  ngOnChanges({ tag }: SimpleChanges): void {
    if (tag) {
      this.propertyType = 'level' in tag.currentValue ? tag.currentValue.level : 'asset';
      this.isEnumTag = hasEnumSchema(tag.currentValue?.description || {}) ? true : false;
      this.externalOwner = !!tag.currentValue.description?.owner && tag.currentValue.description?.owner !== INTERNAL_APP ? tag.currentValue.description?.owner : undefined;
      if (!tag.isFirstChange()) {
        this.tagForm.reset(tag.currentValue.description);
        this.tagForm.get('schema').reset(tag.currentValue.description.schema);
      }
      this.assetWarningMessage$.next([]); // Reset invalid assets check
      this._tagValuesOperations = undefined; // Reset operations done

      this.schemaAsConstraint = { ...tag.currentValue.description?.schema };
    }
  }

  onConstraintNotifyType(type: ConstraintTypeEnum) {
    this.detectedType = type;
    this.schemaTypeOptions = type === 'schema' ? ['schema' as ConstraintTypeEnum] : ConstraintTypes.filter((t) => t !== 'schema');
  }

  onSchemaTypeChanged(option: ConstraintTypeEnum) {
    this.typeAsConstraint = option;
    this.isEnumTag = this.typeAsConstraint === 'specific' ? true : false;
    // change back to original type
    if (this.typeAsConstraint === this.detectedType) {
      this.typeAsConstraint = undefined;
      this.schemaAsConstraint = { ...this.tag.description?.schema } as IJsonConstraint;
    }
  }

  /** When the user apply the changes in the schema */
  applyChanges() {
    const changeOfEnumTag = this.isEnumTag && hasEnumSchema(this.tag.description);
    const currentSchemaType = this.typeAsConstraint || this.detectedType;
    const emitEdit = () => {
      this.editTag.emit({
        key: adjustTagKeyCustomRequirement(this.tag.key, currentSchemaType),
        description: this.getTagKeyDescription(),
        // enum tag value sync is based on opreation only
        operations: changeOfEnumTag ? this._tagValuesOperations : null,
      });
      // in case user want to stay, and continue editing
      this.tag = {
        ...this.tag,
        description: { ...this.getTagKeyDescription() },
      };
    };
    const invalidTags = this.assetWarningMessage$.getValue();

    if (this.getTotalInvalidTags(invalidTags) > 0) {
      // Some assets will be invalid after the changes
      this.openConfirmationDialog(invalidTags)
        .componentInstance.actioned.pipe(first(), takeUntil(this._componentDestroyed$))
        .subscribe(() => emitEdit());
    } else {
      // There is no invalid tags, changes are safe
      emitEdit();
    }

    this.tagForm.markAsPristine();
  }

  /** When the user edit the specific values */
  onEnumChanged(changedValues: { operations: ITagOperationChange; values: string[] }) {
    this.detrimentalValues$.next(findDetrimentalEnumConstraintChange(changedValues.operations));
    this._tagValuesOperations = changedValues.operations;
  }

  onSchemaEdited(change: { valid: boolean; schema: IJsonConstraint }): void {
    this.constraintValid = change.valid;
    if ((this.typeAsConstraint === 'schema' || this.detectedType === 'schema') && JSON.stringify(change.schema) !== JSON.stringify(this.schemaAsConstraint)) {
      this.tagForm.get('advancedJson').patchValue(change.schema);
      this.schemaAsConstraint = change.schema;
      this.tagForm.get('schema').markAsDirty();
    } else {
      ['pattern', 'enum', 'boolean', 'type', 'default', 'minLength', 'maxLength', 'minimum', 'maximum']
        .filter((field) => change.schema[field] && JSON.stringify(change.schema[field]) !== JSON.stringify(this.tagForm.get('schema').get(field).value))
        .forEach((field) => {
          this.tagForm.get('schema').get(field).setValue(change.schema[field]);
          this.schemaAsConstraint = change.schema;
          this.tagForm.get('schema').markAsDirty();
        });
    }
  }

  /** Return the sum of invalid tags for all operations */
  getTotalInvalidTags(invalidAssetList: ITagChangeSummary[]) {
    return invalidAssetList?.reduce((acc, curr) => acc + curr.count, 0);
  }

  ableToAssignDynamicValue(schemaType: ConstraintTypeEnum): boolean {
    return schemaType !== ConstraintEnumType.schema;
  }

  /** return the updated key description object */
  private getTagKeyDescription(): AssetTagKeyDescDTO | TagKeyDescDTO {
    const currentSchemaType: ConstraintTypeEnum = this.typeAsConstraint || this.detectedType;
    let schema: any = {
      type: 'string',
      title: this.tagForm.get('schema').get('title').value,
      examples: this.tagForm.get('schema').get('examples').value,
      ...(this.tagForm.get('schema').get('default').value === '' ? {} : { default: this.tagForm.get('schema').get('default').value }),
      description: this.tagForm.get('schema').get('description').value,
    };

    switch (currentSchemaType) {
      case 'specific':
        schema = { ...schema, enum: this.tagForm.get('schema').get('enum').value };
        break;
      case 'text':
      case 'alphanumeric':
        const minL = this.tagForm.get('schema').get('minLength').value;
        const maxL = this.tagForm.get('schema').get('maxLength').value;
        schema = {
          ...schema,
          pattern: this.tagForm.get('schema').get('pattern').value,
          ...(minL ? { minLength: minL } : {}),
          ...(maxL ? { maxLength: maxL } : {}),
        };
        break;
      case 'numeric':
        const min = this.tagForm.get('schema').get('minimum').value;
        const max = this.tagForm.get('schema').get('maximum').value;
        schema = {
          ...schema,
          type: this.schemaAsConstraint.type,
          ...(min ? { minimum: min } : {}),
          ...(max ? { maximum: max } : {}),
        };
        break;
      case 'boolean':
        schema = {
          ...schema,
          type: this.tagForm.get('schema').get('type')?.value,
        };
        break;
      case 'schema':
        schema = { ...schema, ...this.tagForm.get('advancedJson').value, type: 'object' };
        if (schema.default && typeof schema.default === 'string') {
          schema.default = JSON.parse(schema.default);
        }
        break;
      default:
        break;
    }
    let description = {
      multivalues: ableToAssignMultiValue(currentSchemaType) ? this.tagForm.get('multivalues').value : false,
      ...(this.externalOwner ? { owner: this.externalOwner } : {}),
      schema,
    } as AssetTagKeyDescDTO | TagKeyDescDTO;
    if (this.propertyType === 'asset') {
      //TODO: cms will also change _private to privateTag
      description = { ...description, _private: this.tagForm.get('private').value };
    } else {
      description = { ...description, dynamic: this.tagForm.get('dynamic').value };
    }
    return description;
  }

  private openConfirmationDialog(impactList: ITagChangeSummary[]): ModalRef<InvalidTagDialogComponent> {
    return this._modalService.open<InvalidTagDialogComponent, { impactList: ITagChangeSummary[]; propertyType: PropertyType }>(
      InvalidTagDialogComponent,
      {
        showCloseIcon: true,
        closeOnBackdropClick: true,
        data: {
          impactList,
          propertyType: this.propertyType,
        },
      },
      {
        width: '750px',
      }
    );
  }

  onClose() {
    this.closed.emit();
  }

  ngOnDestroy(): void {
    this._componentDestroyed$.next();
    this._componentDestroyed$.complete();
  }
}
