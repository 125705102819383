import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { SiteListService } from '../services/site-list.service';
import { DashboardComponent } from '../dashboard/dashboard.component';

@Injectable()
/** CanDeactivate guard on dashboard to stop timer that periodically refresh site list */
export class UnloadRefreshSitesGuard implements CanDeactivate<DashboardComponent> {
  constructor(private _siteListService: SiteListService) {}

  canDeactivate(_: DashboardComponent, __: ActivatedRouteSnapshot, ___: RouterStateSnapshot): boolean {
    this._siteListService.stopTimer();
    return true;
  }
}
