import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, first, map, mergeMap, Observable, of, ReplaySubject, share, switchMap, throwError, timer, toArray } from 'rxjs';
import {
  IExperienceTemplate,
  IExperienceTemplateInfo,
  RedundancyStrategy
} from '../models/experience-template.interface';
import {
  SiteDTO,
  DeviceDTO,
  DeviceTypeDTO,
  BoardDTO,
  CreateAllDisplaysInBoardDTO,
  DeviceService,
  DevicesIdsDTOList,
  DisplayDTO,
  DisplaysInBoardService,
  ResponseDevicesSiteDTO,
  SitesService,
  DeviceInfoDTO,
  BoardTagsService,
  TagOperationDTO,
} from '@activia/cm-api';
import { getRequestChainResponse, getRequestChainResponseArray, RequestChain, RequestChainFunction, RequestChainResponses } from '../store/site-sync/request-chain';
import { getNumberOfDevicePerBoard, getDefaultDeviceName, createSiteDevice } from '../components/experience-template/experience-template.utils';
import { generateDisplayFromTemplate, convertToCreateAllDisplaysInBoardDTO } from '../utils/display.utils';
import { getNewBoardTags } from '../utils/iboard.utils';
import { DisplayInputType, PlayerUnit } from '../models/player-unit.enum';
import { dataOnceReady } from '@activia/ngx-components';
import { siteManagementEntities } from '../store/site-management.selectors';
import { Store } from '@ngrx/store';
import { CountryService } from '@activia/geo';
import { getBoardOrgPathFromTags, IOrgPathDefRoot, selectBoardOrgPathDefinition } from '@amp/tag-operation';
import { SITE_MANAGEMENT_MODULE_CONFIG, ISiteManagementConfig } from '@amp/environment';
import { IProperties } from '@activia/json-schema-forms';
import { tap } from 'rxjs/operators';
import { CreateTemplateBoardsUpdateStatus } from '../store/site-management.actions';

/**
 * Defines the information needed to create the experience templates for a site
 * **/
export interface ICreateExperienceTemplateInfo extends IExperienceTemplateInfo {
  // source that provides the site for which the template will be created
  siteSource: SiteDTO | RequestChain<SiteDTO>;

  // list of all board org paths currently existing on the site
  existingSiteBoardOrgPaths: string[];
  // list of all devices types in the system, needed to generate default devices if not provided
  deviceTypes?: DeviceTypeDTO[];
}

/**
 * Temporary service to mock codegen swagger until API is ready
 */

@Injectable({ providedIn: 'root' })
export class ExperienceTemplateService {
  /** Cache all board tags definition */
  private _boardTagsCache$ = this._boardTagsService.findAllTagKeys().pipe(
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: () => timer(10000), // Keep the cache for 10 sec
      resetOnComplete: false,
      resetOnError: true,
    })
  );

  constructor(
    private httpClient: HttpClient,
    private _deviceService: DeviceService,
    private _sitesService: SitesService,
    private _displaysInBoardService: DisplaysInBoardService,
    private _boardTagsService: BoardTagsService,
    private _countryService: CountryService,
    private _store: Store,
    @Inject(SITE_MANAGEMENT_MODULE_CONFIG) @Optional() private _siteManagementConfig: ISiteManagementConfig
  ) {}

  /** Fetch all experience template */
  getExperienceTemplates(): Observable<IExperienceTemplate[]> {
    return this.httpClient.get<IExperienceTemplate[]>(this._siteManagementConfig.experienceTemplatePath);
  }

  /** Fetch all experience template */
  addExperienceTemplate(experienceTemplate: IExperienceTemplate): Observable<IExperienceTemplate> {
    return of(experienceTemplate);
  }

  /** Fetch all experience template */
  deleteExperienceTemplate(experienceTemplateId: string): Observable<string> {
    return of(experienceTemplateId);
  }

  /** Fetch all experience template */
  updateExperienceTemplate(experienceTemplate: IExperienceTemplate): Observable<IExperienceTemplate> {
    return of(experienceTemplate);
  }

  /**
   * Creates a chain of requests executed in sequence, allowing to create one to multiple experience template for a site.
   * Creates boards, devices, tags and displays.
   * **/
  createExperienceTemplatesRequestChain(
    experiencesInfo: ICreateExperienceTemplateInfo,
    playerCountPerDevice: number,
    outputCountPerPlayer: number,
    boardOrgPathDef: IOrgPathDefRoot,
    tagsDefinition: Record<string, IProperties>
  ): RequestChain {
    const { experiences, siteSource, deviceTypes, deviceAction, existingSiteBoardOrgPaths } = experiencesInfo;

    const requestChain = new RequestChain();

    const shouldCreateDevices = deviceAction === 'provision';

    // Build a flat list of all experiences to create based on how many times each experience need to be created
    const experienceList: { experience: IExperienceTemplate; devicesPerBoard?: Record<number, DeviceDTO[]> }[] = experiences.reduce((res, { experience, devicesPerBoard, count }) => {
      Array.from(Array(count)).forEach(() => res.push({ experience, devicesPerBoard }));
      return res;
    }, []);

    experienceList.forEach(({ experience, devicesPerBoard }, experienceIndex) => {
      // 1) create all boards of template
      experience.boards
        .filter((board) => {
          // If board already exist we skip it
          const fullOrgPath = getBoardOrgPathFromTags(boardOrgPathDef, experience.tags, board.label);
          return experience.allowMultiple || !existingSiteBoardOrgPaths.includes(fullOrgPath);
        })
        .forEach((board, boardIndex) => {
          // a) create all devices of board if required
          if (shouldCreateDevices) {
            // check if the info to create the devices is provided (form filled in the ui)
            const devicesInfoProvided = !!devicesPerBoard;
            if (devicesInfoProvided) {
              const boardDevicesToCreate = devicesPerBoard[boardIndex];
              // register a request for each device to create
              boardDevicesToCreate.forEach((deviceToCreate, deviceIndex) => {
                const createDevice$: RequestChainFunction<DeviceDTO> = () => this._deviceService.createDevice(deviceToCreate);

                requestChain.registerRequest({
                  id: `create-device|${experienceIndex}-${boardIndex}-${deviceIndex}`,
                  request$: createDevice$,
                });
              });
            } else {
              // generate default devices based on the experience template information
              const deviceToCreateCount = getNumberOfDevicePerBoard(board, playerCountPerDevice, outputCountPerPlayer);
              Array.from(Array(deviceToCreateCount)).forEach((_, deviceIndex) => {
                const createDevice$: RequestChainFunction<DeviceDTO> = (responses: RequestChainResponses) => {
                  const site = this._getSiteFromPreviousStep(responses, siteSource);
                  // the devices for the board may have already been created via the UI
                  const defaultDeviceName = getDefaultDeviceName(site, board.player.namePattern, deviceIndex);
                  const deviceType = deviceTypes.find(({ model }) => board.player.model === model);
                  const deviceToCreate = createSiteDevice(this._countryService, site, deviceType, board.player.ipAdress, defaultDeviceName);
                  return this._deviceService.createDevice(deviceToCreate);
                };
                requestChain.registerRequest({
                  id: `create-device|${experienceIndex}-${boardIndex}-${deviceIndex}`,
                  request$: createDevice$,
                });
              });
            }

            // b) attach devices to site
            const attachDevicesToSite$: RequestChainFunction<ResponseDevicesSiteDTO> = (responses: RequestChainResponses) => {
              const devicesCreated = getRequestChainResponseArray<DeviceDTO>(responses, `create-device|${experienceIndex}-${boardIndex}`);

              const site = this._getSiteFromPreviousStep(responses, siteSource);
              const dto: DevicesIdsDTOList = { create: devicesCreated.map(({ deviceInfo }) => deviceInfo.deviceId) as any };
              return this._sitesService.attachDetachDevicesToSite(site.id, dto);
            };
            requestChain.registerRequest({
              id: `attach-devices-to-site|${experienceIndex}-${boardIndex}`,
              request$: attachDevicesToSite$,
            });
          }

          // c) create board
          const createBoard$: RequestChainFunction<BoardDTO> = (responses: RequestChainResponses) => {
            const site = this._getSiteFromPreviousStep(responses, siteSource);
            const devicesCreated = getRequestChainResponseArray<DeviceDTO>(responses, `create-device|${experienceIndex}-${boardIndex}`);

            // board.displays.map((display, displayIdx) => generateDisplayFromTemplate(display, displayIdx));
            const boardDisplays$: Observable<DisplayDTO[]> = of(board.displays.map((display, displayIdx) => generateDisplayFromTemplate(display, displayIdx)));

            return boardDisplays$.pipe(
              switchMap((boardDisplays) => {
                if (shouldCreateDevices) {
                  return this._connectDisplaysToDevices(
                    board.redundancyStrategy,
                    boardDisplays,
                    devicesCreated.map((device) => device.deviceInfo)
                  );
                } else {
                  return of(boardDisplays);
                }
              }),
              switchMap((boardDisplays) => {
                const templateBoard = { name: board.label, displays: boardDisplays, order: boardIndex };

                return this._sitesService
                  .addBoardToSite(site.id, {
                    name: templateBoard.name,
                    order: templateBoard.order,
                  })
                  .pipe(map((resp) => ({ ...templateBoard, ...resp }))); // add extra info returned by the backend (especially the id for the next steps)
              })
            );
          };

          requestChain.registerRequest({ id: `create-board|${experienceIndex}-${boardIndex}`, request$: createBoard$ });

          // create displays
          const createDisplays$: RequestChainFunction = (responses: RequestChainResponses) => {
            // get board created at previous step
            const templateBoard = getRequestChainResponse<BoardDTO>(responses, `create-board|${experienceIndex}-${boardIndex}`);
            const displaysDTOs: CreateAllDisplaysInBoardDTO = convertToCreateAllDisplaysInBoardDTO(templateBoard.displays);
            return this._displaysInBoardService.createOrReplaceAllDisplaysInBoard(templateBoard.id, displaysDTOs);
          };
          requestChain.registerRequest({ id: `create-displays|${experienceIndex}-${boardIndex}`, request$: createDisplays$ });

          // create tags
          const createTags$: RequestChainFunction = (responses: RequestChainResponses) => {
            const boardTags = experience.allowMultiple ? getNewBoardTags(boardOrgPathDef, tagsDefinition, board.label, experience.tags, existingSiteBoardOrgPaths) : experience.tags;

            // add to our list of created orgpath so next board will be created with an incremented index
            existingSiteBoardOrgPaths.push(getBoardOrgPathFromTags(boardOrgPathDef, boardTags, board.label));

            // get board created at previous step
            const templateBoard = getRequestChainResponse<BoardDTO>(responses, `create-board|${experienceIndex}-${boardIndex}`);

            const operations = Object.keys(boardTags).map((tag) => ({ op: 'add', key: tag, newValues: [boardTags[tag].toString()] } as TagOperationDTO));

            return this._boardTagsService.patchTagsForEntity(templateBoard.id, operations).pipe(map(() => boardTags));
          };
          requestChain.registerRequest({ id: `create-tags|${experienceIndex}-${boardIndex}`, request$: createTags$ });
        });
    });

    return requestChain;
  }

  /**
   * Create one to multiple experience template for a site.
   * Creates boards, devices, tags and displays.
   * **/
  createExperienceTemplates(experiencesInfo: ICreateExperienceTemplateInfo): Observable<{
    boards: BoardDTO[];
    displays: DisplayDTO[];
    devices: DeviceDTO[];
    tags: Record<string, unknown>[];
  }> {
    return combineLatest([this._store.pipe(siteManagementEntities.siteConfigData$), this._store.select(selectBoardOrgPathDefinition), this._boardTagsCache$]).pipe(
      first(),
      mergeMap(([{ defaultPlayerCountPerDevice, defaultOutputCountPerPlayer }, boardOrgPathDef, tagKeys]) => {
        // Format tagKeys into Record<string, IProperties>
        const tagsDefinition = Object.entries(tagKeys).reduce((acc, [key, value]) => {
          acc[key] = value.schema as IProperties;
          return acc;
        }, {} as Record<string, IProperties>);

        return this.createExperienceTemplatesRequestChain(experiencesInfo, defaultPlayerCountPerDevice, defaultOutputCountPerPlayer, boardOrgPathDef, tagsDefinition)
          .resume$()
          .pipe(
            tap(({ requestId, loadingState }) => {
              this._store.dispatch(
                CreateTemplateBoardsUpdateStatus({
                  stepId: requestId,
                  loadingState,
                })
              );
            }),
            toArray(),
            mergeMap((events) => {
              const [lastResponse] = events.slice(-1);

              if (!lastResponse) {
                // All board already exist
                return throwError(() => ({ error: 'All boards already exist' }));
              } else if (lastResponse.errorInfo) {
                // Error in creation
                return throwError(() => ({ error: lastResponse.errorInfo.message }));
              } else {
                // Success
                let createdBoardDTOs = getRequestChainResponseArray<BoardDTO>(lastResponse.requestChainState.responses, 'create-board');
                const createdDeviceDtos = getRequestChainResponseArray<DeviceDTO>(lastResponse.requestChainState.responses, 'create-device');
                const createdDisplayDtos = getRequestChainResponseArray<DisplayDTO>(lastResponse.requestChainState.responses, 'create-displays');
                const createdTags = getRequestChainResponseArray<Record<string, unknown>>(lastResponse.requestChainState.responses, 'create-tags');
                const successfullyAttachedDeviceIds = getRequestChainResponseArray<ResponseDevicesSiteDTO>(lastResponse.requestChainState.responses, 'attach-devices-to-site')
                  .map(({ create }) =>
                    Array.from(create)
                      .filter((device: any) => device.code === 200)
                      .map((device) => device.id)
                  )
                  .flat();

                createdBoardDTOs = createdBoardDTOs.map((board) => ({
                  ...board,
                  displays: createdDisplayDtos
                    .flat()
                    .filter((display) => board.id === display.parentBoardId)
                    .sort((a, b) => a.boardScreenIdx - b.boardScreenIdx),
                }));

                const createdDevices = createdDeviceDtos.filter(({ id }) => successfullyAttachedDeviceIds.includes(id));

                return of({
                  boards: createdBoardDTOs,
                  displays: createdDisplayDtos,
                  devices: createdDevices,
                  tags: createdTags,
                });
              }
            })
          );
      })
    );
  }

  /** This function determines how displays are connected to devices when boards are created from template with new devices */
  private _connectDisplaysToDevices(redundancyStrategy: RedundancyStrategy, displays: DisplayDTO[], devices: DeviceInfoDTO[]): Observable<DisplayDTO[]> {
    switch (redundancyStrategy) {
      case RedundancyStrategy.NONE:
        return this._noneStrategy(displays, devices);
      case RedundancyStrategy.ACTIVE_PASSIVE:
        return this._passiveActiveStrategy(displays, devices);
      case RedundancyStrategy.ACTIVE_FAILOVER:
        return this._activeFailoverStrategy(displays, devices);
      default:
        return this._noneStrategy(displays, devices);
    }
  }

  /** Connect 1 player to 1 display without backup */
  private _noneStrategy(displays: DisplayDTO[], devices: DeviceInfoDTO[], displayDevicesType = DisplayInputType.PRIMARY): Observable<DisplayDTO[]> {
    return dataOnceReady(this._store.pipe(siteManagementEntities.siteConfigData$), this._store.pipe(siteManagementEntities.siteConfigDataState$)).pipe(
      first(),
      map(({ defaultPlayerCountPerDevice, defaultOutputCountPerPlayer }) => {
        const playerCount = defaultPlayerCountPerDevice;
        const outputCount = defaultOutputCountPerPlayer;

        return displays.map((display, connectorIdx) => {
          // Increment device index everytime we reach the maximum player count
          const deviceIdx = Math.floor(connectorIdx / (playerCount * outputCount));

          // Increment player index everytime we reach the maximum output index
          const playerIdx = Math.floor(connectorIdx / outputCount) % playerCount;
          const outputIdx = connectorIdx % outputCount; // Reset output index when we reach the maximum output

          display.inputs[displayDevicesType] = {
            deviceId: devices[deviceIdx].deviceId,
            playerId: playerIdx,
            output: outputIdx,
          };

          return display;
        });
      })
    );
  }

  /** Dedicate players for active display only, and  */
  private _passiveActiveStrategy(displays: DisplayDTO[], devices: DeviceInfoDTO[]): Observable<DisplayDTO[]> {
    const mainDevices = devices.slice(0, devices.length / 2);
    const backupDevices = devices.slice(devices.length / 2, devices.length);

    // Connect all main devices with Primary display
    return this._noneStrategy(displays, mainDevices, DisplayInputType.PRIMARY).pipe(
      // Then connect all backup devices with backup display
      switchMap((connectedDisplays) => this._noneStrategy(connectedDisplays, backupDevices, DisplayInputType.BACKUP))
    );
  }

  /** Each device is connected to a primary display and a different backup display */
  private _activeFailoverStrategy(displays: DisplayDTO[], devices: DeviceInfoDTO[]): Observable<DisplayDTO[]> {
    return dataOnceReady(this._store.pipe(siteManagementEntities.siteConfigData$), this._store.pipe(siteManagementEntities.siteConfigDataState$)).pipe(
      first(),
      map(({ defaultOutputCountPerPlayer }) => {
        // If we have at least 2 output we can use the same logical player for the primary and backup connection
        const nextPlayerId = defaultOutputCountPerPlayer >= 2 ? PlayerUnit.PRIMARY : PlayerUnit.SECONDARY;
        const nextOutputId = defaultOutputCountPerPlayer >= 2 ? 1 : 0;

        return displays.map((display, displayIdx) => {
          const nextDeviceIdx = (displayIdx + 1) % devices.length;

          display.inputs[DisplayInputType.PRIMARY] = {
            deviceId: devices[displayIdx].deviceId,
            playerId: PlayerUnit.PRIMARY,
            output: 0,
          };

          display.inputs[DisplayInputType.BACKUP] = {
            deviceId: devices[nextDeviceIdx].deviceId,
            playerId: nextPlayerId,
            output: nextOutputId,
          };

          return display;
        });
      })
    );
  }

  private _getSiteFromPreviousStep(responses: RequestChainResponses, siteSource: SiteDTO | RequestChain<SiteDTO>): SiteDTO {
    if (siteSource.constructor.name === 'RequestChain') {
      return getRequestChainResponse<SiteDTO>(responses, 'create-site', 'update-site');
    }
    return siteSource as SiteDTO;
  }
}
