import { BoardTagsService, TagKeyDescDTO } from '@activia/cm-api';
import { IProperties } from '@activia/json-schema-forms';
import { AsyncDataState, dataOnceReady, IModalConfig, IStandardDialogData, LoadingState, ModalDialogType, ModalService, ThemeType } from '@activia/ngx-components';
import { ErrorHandlingService } from '@amp/error';
import {
  EngineTagLevel,
  IOrgPathDefNode,
  ITagOperationChange,
  SaveBoardOrgPathDefinition,
  selectBoardOrgPathDefinition,
  selectBoardOrgPathDefinitionState,
  selectBoardOrgPathSaveState,
  TagOperationService,
} from '@amp/tag-operation';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { filter, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { v4 as uuidV4 } from 'uuid';

export interface IOrgPathNode extends IOrgPathDefNode {
  id?: string;
  parent?: IOrgPathNode;
  childOneOf?: IOrgPathNode[];
}

export interface IOrgPathNodeErrors {
  hasDuplicate?: string[]; // List of duplicate conditions
  missingConditions?: string[]; // List of missing conditions
  noChild?: boolean; // A "tag" node should have a child
  notALeaf: boolean; // A "Name" node should have no child
  noType: boolean; // The node is neither a "tag" or a "name"
}

export interface BoardOrgpathState {
  selectedNode: string | undefined;
  nodeEntities: Record<string, IOrgPathNode>;
  tagsDefinitions: Record<string, TagKeyDescDTO>;
  state: AsyncDataState;
  isSaved: boolean;
}

const initialState: BoardOrgpathState = {
  selectedNode: undefined,
  nodeEntities: {},
  tagsDefinitions: {},
  state: LoadingState.INIT,
  isSaved: true,
};

@Injectable()
export class BoardOrgpathStore extends ComponentStore<BoardOrgpathState> implements OnDestroy {
  //#region Selectors
  selectedNode$ = this.select((state) => state.nodeEntities[state.selectedNode]);

  selectOrgPathDefinition$ = this.select((state) => Object.values(state.nodeEntities).find((e) => !e.parent));

  selectNodeEntities$ = this.select((state) => state.nodeEntities);

  selectTagsDefinitions$ = this.select((state) => state.tagsDefinitions);

  selectLoadingState$ = this.select((state) => state.state);

  selectIsSaved$ = this.select((state) => state.isSaved);

  selectErrors$ = this.select(this.selectNodeEntities$, this.selectTagsDefinitions$, (nodeEntities, tagsDefinitions) => this._getErrorsInNodes(nodeEntities, tagsDefinitions));
  //#endregion

  onDestroyed$ = new Subject<void>();

  constructor(
    private _store: Store,
    private _boardTagsService: BoardTagsService,
    private _tagOperationService: TagOperationService,
    private _transloco: TranslocoService,
    private _errorHandlingService: ErrorHandlingService,
    private _modalService: ModalService
  ) {
    super(initialState);

    // Loading while waiting for org path definition
    this.patchState({ state: LoadingState.LOADING });

    // Initialize tags definitions
    this._boardTagsService.findAllTagKeys().subscribe((tags) => this.patchState({ tagsDefinitions: tags }));

    // Initialize org path definition
    dataOnceReady(this._store.select(selectBoardOrgPathDefinition), this._store.select(selectBoardOrgPathDefinitionState), 1).subscribe((orgPathDef) => {
      this.patchState({ nodeEntities: this._linkChildToParent(cloneDeep(orgPathDef.root)), state: LoadingState.LOADED });
    });
  }

  //#region Reducer

  /** Change the selected node in the tree */
  selectNode = this.updater((state, nodeId: string | undefined) => ({
    ...state,
    selectedNode: nodeId,
  }));

  /** Add a new root to the tree */
  addRootNode = this.updater((state) => {
    // add new child to this node
    const newNode = { id: uuidV4() } as IOrgPathNode;

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [newNode.id]: newNode,
      },
      selectedNode: newNode.id,
      isSaved: false,
    };
  });

  /** Add a new child node at the specified node ID */
  addNewNode = this.updater((state, nodeId: string) => {
    const node = state.nodeEntities[nodeId];

    // add new child to this node
    const newNode = { parent: node, id: uuidV4() } as IOrgPathNode;
    if (node.childOneOf) {
      node.childOneOf.push(newNode);
    } else {
      node.childOneOf = [newNode];
    }

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [newNode.id]: newNode,
      },
      selectedNode: newNode.id,
      isSaved: false,
    };
  });

  /** Delete the specified Node id */
  deleteNode = this.updater((state, nodeId: string) => {
    const node = state.nodeEntities[nodeId];

    // Delete node from the tree
    if (node.parent) {
      const index = node.parent.childOneOf.findIndex((e) => e.id === nodeId);
      node.parent.childOneOf.splice(index, 1);
    }

    // Remove all children associated with current node
    const deletedIds = this._getAllChildren(node);

    // Rebuild dictionnary without deleted nodes
    const nodeEntities = Object.keys(state.nodeEntities)
      .filter((currentNodeId) => !deletedIds.includes(currentNodeId))
      .reduce((acc, curr) => {
        acc[curr] = state.nodeEntities[curr];
        return acc;
      }, {});

    return {
      ...state,
      nodeEntities,
      selectedNode: node.id === state.selectedNode ? null : state.selectedNode,
      isSaved: false,
    };
  });

  /** Change the condition of the node (dependent on the parent JSON schema)  */
  editNodeCondition = this.updater((state, condition?: string | number) => {
    const selectedNode = state.nodeEntities[state.selectedNode];
    selectedNode.dependentItem = condition?.toString();

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [selectedNode.id]: selectedNode,
      },
      isSaved: false,
    };
  });

  /** Change the type of the node in the tree (name or tag) */
  editNodeTagOrProperty = this.updater((state, newTagProperty: string) => {
    const selectedNode = state.nodeEntities[state.selectedNode];

    if (newTagProperty === 'name') {
      selectedNode.property = newTagProperty;
      delete selectedNode.tag;

      // Default Schema for "name"
      selectedNode.schema = {
        type: 'string',
        pattern: '^[a-zA-Z0-9]*$',
        title: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_EDITOR.BOARD_NAME_TITLE_30'),
      };
    } else {
      selectedNode.tag = newTagProperty;
      delete selectedNode.property;
      delete selectedNode.schema;
    }

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [selectedNode.id]: selectedNode,
      },
      isSaved: false,
    };
  });

  /** Change the JSON schema for the selected node of type "Board Name" */
  editPropertySchema = this.updater((state, schema: IProperties) => {
    const selectedNode = state.nodeEntities[state.selectedNode];
    selectedNode.schema = {
      // Keep all properties coming from 'description'
      ...(selectedNode.schema.title && { title: selectedNode.schema.title }),
      ...(selectedNode.schema.description && { description: selectedNode.schema.description }),
      ...((selectedNode.schema as any).examples && { examples: (selectedNode.schema as any).examples }),
      ...((selectedNode.schema as any).default && { default: (selectedNode.schema as any).default }),

      // Add properties from 'constraint'
      ...(schema.type && { type: schema.type }),
      ...(!isNaN(schema.minimum) && { minimum: schema.minimum }),
      ...(!isNaN(schema.maximum) && { maximum: schema.maximum }),
      ...(schema.enum && { enum: schema.enum }),
      ...(!isNaN(schema.maxLength) && { maxLength: schema.maxLength }),
      ...(!isNaN(schema.minLength) && { minLength: schema.minLength }),
      ...(schema.pattern && { pattern: schema.pattern }),
    };

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [selectedNode.id]: selectedNode,
      },
      isSaved: false,
    };
  });

  /** Change the Description property of the selected node */
  editPropertyDescription = this.updater((state, schema: Partial<IProperties>) => {
    const selectedNode = state.nodeEntities[state.selectedNode];
    selectedNode.schema = {
      ...selectedNode.schema,
      title: schema.title,
      description: schema.description,
      examples: (schema as any).examples,
      default: (schema as any).default,
    } as any;

    return {
      ...state,
      nodeEntities: {
        ...state.nodeEntities,
        [selectedNode.id]: selectedNode,
      },
      isSaved: false,
    };
  });

  /** Add a new Json schema definition of a tag  */
  addTagDefinition = this.updater((state, tag: { key: string; description: TagKeyDescDTO }) => ({
    ...state,
    tagsDefinitions: {
      ...this.get().tagsDefinitions,
      [tag.key]: tag.description,
    },
  }));

  /** Make node higher in the "dependentItem" priority  */
  moveNodeUp = this.updater((state, nodeId: string) => {
    const node = state.nodeEntities[nodeId];

    if (node.parent) {
      const index = node.parent.childOneOf.findIndex((e) => e.id === node.id);

      if (index > 0) {
        node.parent.childOneOf.splice(index, 1); // remove node at the current position
        node.parent.childOneOf.splice(index - 1, 0, node); // add it back 1 position up
      }
    }

    return {
      ...state,
      isSaved: false,
    };
  });

  /** Make node lower in the "dependentItem" priority  */
  moveNodeDown = this.updater((state, nodeId: string) => {
    const node = state.nodeEntities[nodeId];

    if (node.parent) {
      const index = node.parent.childOneOf.findIndex((e) => e.id === node.id);

      if (index < node.parent.childOneOf.length - 1) {
        node.parent.childOneOf.splice(index, 1); // remove node at the current position
        node.parent.childOneOf.splice(index + 1, 0, node); // add it back 1 position down
      }
    }

    return {
      ...state,
      isSaved: false,
    };
  });
  //#endregion

  //#region Effects

  /** Add a new board tag in backend */
  readonly addNewTag = this.effect((tag$: Observable<{ key: string; description: TagKeyDescDTO }>) =>
    tag$.pipe(
      switchMap((tag) =>
        this._boardTagsService.updateTagKey(tag.key, tag.description).pipe(
          tapResponse(
            () => {
              this.addTagDefinition(tag);
              this.editNodeTagOrProperty(tag.key);
            },
            (error) => this._errorHandlingService.catchError(error as HttpErrorResponse, undefined, 'Error add new tag')
          )
        )
      )
    )
  );

  /** Edit a tag in backend */
  readonly saveTag = this.effect((tag$: Observable<{ key: string; description: TagKeyDescDTO; operations: ITagOperationChange }>) =>
    tag$.pipe(
      switchMap((tag) => this._boardTagsService.updateTagKey(tag.key, tag.description).pipe(map(() => tag))),
      switchMap(({ key, description, operations }) =>
        this._tagOperationService.syncTagValuesAfterKeyUpdate({ key, description, level: EngineTagLevel.BOARD }, operations).pipe(
          tapResponse(
            () => {
              this.addTagDefinition({ key, description });
              this.editNodeTagOrProperty(key);
            },
            (error) => this._errorHandlingService.catchError(error as HttpErrorResponse, undefined, 'Error save tag')
          )
        )
      )
    )
  );

  /** Remove node. If node is a tag and is already used show modal to confirm deletion */
  readonly removeNode = this.effect((id$: Observable<string>) =>
    id$.pipe(
      map((id) => this.get().nodeEntities[id]),
      switchMap((node) => {
        if (node.tag) {
          // Check if tag is already used
          return this._boardTagsService
            .getInvalidCountForNewTagKeyDesc(node.tag, {
              dynamic: false,
              multivalues: false,
              schema: {
                type: 'string',
                pattern: '/(?=a)b/', // Always failing pattern to get the count of all boards using this tag
              },
            })
            .pipe(
              switchMap((res) => {
                if (res.count > 0) {
                  // Tag is already used
                  return this._showConfirmationModal(node.tag, res.count).pipe(map(() => node.id)); // if user cancel, then observable complete
                } else {
                  // Tag is unused and safe to delete
                  return of(node.id);
                }
              })
            );
        } else {
          return of(node.id);
        }
      }),
      tapResponse(
        (id: string) => this.deleteNode(id),
        (error) => this._errorHandlingService.catchError(error as HttpErrorResponse, undefined, 'Error deleting node')
      )
    )
  );

  /** Save the definition in database */
  saveDefinition(): Observable<boolean> {
    const root = Object.values(this.get().nodeEntities).find((e) => !e.parent); // Get root of the tree

    const sanitizedRoot = this._sanitizeNode(root); // Sanitize tree

    // Dispatch action to save tree
    this._store.dispatch(SaveBoardOrgPathDefinition({ boardOrgPathDefinition: { type: 'board', root: sanitizedRoot } }));

    // Listen at the result of the save action
    const isSaved$ = this._store.select(selectBoardOrgPathSaveState).pipe(
      filter((state) => state !== LoadingState.LOADING),
      map((state) => state === LoadingState.LOADED), // Board org path def saved successfully
      take(1),
      takeUntil(this.onDestroyed$)
    );

    // Change state if saved successfully
    isSaved$.subscribe((isSaved) => {
      if (isSaved) {
        this.patchState({ isSaved: true });
      }
    });

    return isSaved$;
  }

  //#endregion

  hasUnsavedChanges() {
    return !this.get().isSaved;
  }

  ngOnDestroy(): void {
    this.onDestroyed$.next();
    this.onDestroyed$.complete();
  }

  /** Sanitize board org path tree to link children with parent and add ID to node */
  private _linkChildToParent(currentNode: IOrgPathNode, nodeEntities?: Record<string, IOrgPathNode>): Record<string, IOrgPathNode> {
    if (!nodeEntities) {
      nodeEntities = {};
    }

    currentNode.id = uuidV4(); // Add id to the node
    nodeEntities[currentNode.id] = currentNode;

    if (currentNode?.childOneOf?.length) {
      for (const childNode of currentNode.childOneOf) {
        this._linkChildToParent(childNode, nodeEntities);
        childNode.parent = currentNode;
      }
    }

    return nodeEntities;
  }

  private _getAllChildren(node: IOrgPathNode, ids?: string[]): string[] {
    if (!ids) {
      ids = []; // Start with empty list
    }

    ids.push(node.id);

    // Get all children
    if (node?.childOneOf?.length) {
      for (const childNode of node.childOneOf) {
        this._getAllChildren(childNode, ids);
      }
    }

    return ids;
  }

  /** Sanitize the tree to remove useless attribute and break recursive reference */
  private _sanitizeNode(node: IOrgPathNode): IOrgPathDefNode {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parent, id, ...sanitizedNode } = { ...node }; // Remove id and parent attribute

    return {
      ...sanitizedNode,
      childOneOf: sanitizedNode.childOneOf?.map((childNode) => this._sanitizeNode(childNode)),
    };
  }

  /** Get all errors in the tree */
  private _getErrorsInNodes(nodeEntities: Record<string, IOrgPathNode>, tagsDefinitions: Record<string, TagKeyDescDTO>): Record<string, IOrgPathNodeErrors> {
    return Object.keys(nodeEntities).reduce((errors, nodeId) => {
      const currentNode = nodeEntities[nodeId];

      // Check condition when node is 'tag'
      if (currentNode.tag) {
        if (currentNode.childOneOf?.length) {
          // Check if there is no duplicate condition in children
          const conditionList = currentNode.childOneOf.map((e) => e.dependentItem);
          const duplicate = conditionList.filter((e, i, a) => a.indexOf(e) !== i);
          if (duplicate.length) {
            errors[nodeId] = { ...errors[nodeId], hasDuplicate: duplicate };
          }

          // Check if there is no missing condition when tag is an enum
          const enumList = (tagsDefinitions[currentNode.tag]?.schema as IProperties)?.enum;
          if (enumList?.length && !(conditionList.some((e) => !e) || enumList.every((e) => conditionList.includes(e)))) {
            errors[nodeId] = { ...errors[nodeId], missingConditions: enumList.filter((e) => !conditionList.includes(e)) };
          }
        } else {
          // Error when tag node has no child
          errors[nodeId] = { ...errors[nodeId], noChild: true };
        }
      }
      // Check condition when node is 'name'
      else if (currentNode.property) {
        // Check if node is property name, then no children
        if (currentNode.childOneOf?.length) {
          errors[nodeId] = { ...errors[nodeId], notALeaf: true };
        }
      } else {
        errors[nodeId] = { ...errors[nodeId], noType: true };
      }

      return errors;
    }, {} as Record<string, IOrgPathNodeErrors>);
  }

  private _showConfirmationModal(tagKey: string, count: number): Observable<boolean> {
    const dialogData: IStandardDialogData = {
      type: ModalDialogType.Confirm,
      theme: ThemeType.DANGER,
      title: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_TREE.MODAL_DELETE_CONFIRMATION_TITLE_40'),
      message: this._transloco.translate('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NODE_TREE.MESSAGE_100', { tagKey, count }),
      closeActionLabel: this._transloco.translate('button.cancel'),
      acceptActionLabel: this._transloco.translate('button.delete'),
    };

    const dialogConfig: IModalConfig<IStandardDialogData> = {
      showCloseIcon: true,
      closeOnBackdropClick: true,
      data: dialogData,
    };
    const modalRef = this._modalService.openStandardDialog(dialogConfig);
    return modalRef.componentInstance.accepted.pipe(take(1), takeUntil(this.onDestroyed$), takeUntil(modalRef.afterClosed));
  }
}
