import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Store } from '@ngrx/store';
import * as SiteManagementAction from '../store/site-management.actions';

@Injectable({ providedIn: 'root' })
/** CanDeactivate guard when leaving site management module */
export class UnloadSelectedSitesGuard implements CanDeactivate<void> {
  constructor(private store: Store) {}

  canDeactivate(): boolean {
    this.store.dispatch(SiteManagementAction.UnloadSelectedSites());
    return true;
  }
}
