<avn-dialog-layout>
  <avn-dialog-header
    [showCloseIcon]="dialogConfig.showCloseIcon"
    [title]="
      'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.EDIT_BOARD_100'
        | transloco: { action: dialogConfig.data ? ('siteManagementScope.SITE_MANAGEMENT.GLOBAL.EDIT_10' | transloco) : ('siteManagementScope.SITE_MANAGEMENT.GLOBAL.ADD_10' | transloco) }
    "
    (closeClicked)="onCancel()"
  ></avn-dialog-header>

  <avn-dialog-content avnScroll>
    <ng-container *avnAsyncData="''; state: boardOrgPathDefState$ | async; loaderType: 'linear'; emptyTemplate: errorTemplate; errorTemplate: errorTemplate">
      <form [formGroup]="boardFormGroup">
        <ng-container *ngIf="boardOrgPathDef$ | async as boardOrgPathDef">
          <ng-container *ngIf="boardOrgPathDef.root | applyFunction: hasNameProperty:[]:this; else noOrgPathForm">
            <!-- Organizational Path -->
            <ng-container *ngIf="initialOrgPathValue$ | async as initialValues">
              <amp-board-org-path-form
                *ngIf="tagDefinition$ | async as tagDefinition"
                [form]="boardFormGroup.get('orgPathArray')"
                [tagDefinition]="tagDefinition"
                [nodeDefinition]="boardOrgPathDef.root"
                [initialValues]="initialValues"
                (schemaResult)="getDependentItem($event)"
              >
              </amp-board-org-path-form>
            </ng-container>
          </ng-container>

          <ng-template #noOrgPathForm>
            <!-- Default Board Name if doesn't exist in board org path schema -->
            <avn-form-field>
              <avn-input
                formControlName="boardName"
                (changed)="getDependentItem({ name: $event })"
                [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.BOARD_NAME_15' | transloco"
              ></avn-input>
              <avn-form-field-error key="required" [message]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.FIELD_REQUIRED_30' | transloco"></avn-form-field-error>
              <avn-form-field-error key="duplicate" [message]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.DUPLICATE_BOARD_NAME_60' | transloco"></avn-form-field-error>
            </avn-form-field>
          </ng-template>

          <avn-form-field-error
            *ngIf="boardFormGroup.errors?.duplicate"
            [message]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.DUPLICATE_BOARD_NAME_60' | transloco"
          ></avn-form-field-error>
        </ng-container>

        <!-- Board Size -->
        <avn-form-field *ngIf="!dialogConfig.data">
          <label class="grid-size-selector-label" [class.disabled]="isBoardLocked()">
            {{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.BOARD_LAYOUT_15' | transloco }}
          </label>
          <div
            #overlayOrigin="cdkOverlayOrigin"
            cdkOverlayOrigin
            (click)="openGridSizeSelector()"
            class="grid-size-selector"
            [class.disabled]="isBoardLocked()"
            [class.touched]="this.boardFormGroup.get('boardSize').touched && !boardFormGroup.get('boardSize').errors"
            [class.error]="this.boardFormGroup.get('boardSize').touched && boardFormGroup.get('boardSize').errors?.required"
          >
            <div>
              <span *ngIf="boardFormGroup.value.boardSize; else layoutPlaceholder">
                {{ boardFormGroup.value.boardSize.column + ' x ' + boardFormGroup.value.boardSize.row }}
              </span>
              <ng-template #layoutPlaceholder>
                <span class="emptyLayout">{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.EMPTY_LAYOUT_10' | transloco }}</span>
              </ng-template>
            </div>
            <avn-icon [icon]="'hardware:keyboard_arrow_down'" [class]="{ active: !!isConnectedElementOpened }" [fontSize]="18"></avn-icon>
          </div>
          <avn-connected-element [overlayOrigin]="overlayOrigin" [minWidth]="'auto'" [opened]="isConnectedElementOpened" (clickOutside)="onClickOutside()">
            <div avnScroll class="connected-element">
              <avn-grid-size-selector
                [avnTheme]="'primary'"
                [minGridSize]="{ row: 3, column: 5 }"
                [maxGridSize]="{ row: 5, column: 10 }"
                [selectedGridSize]="boardFormGroup.value.boardSize"
                [minSelectedGridSize]="minSelectedGridSize"
                (gridItemClicked)="onGridSizeSelected($event)"
              >
              </avn-grid-size-selector>
            </div>
          </avn-connected-element>

          <span *ngIf="this.boardFormGroup.get('boardSize').touched && boardFormGroup.get('boardSize').errors?.required" class="grid-size-selector-error">
            {{ 'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ERROR.FIELD_REQUIRED_30' | transloco }}
          </span>
        </avn-form-field>
      </form>
    </ng-container>

    <ng-template #errorTemplate>
      <div class="no-data">
        <avn-empty-data-message
          icon="alert:error"
          [text]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.ERRORS.ORG_PATH.MISSING_DEFINITION_TITLE_60' | transloco"
          [subText]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.ERRORS.ORG_PATH.MISSING_DEFINITION_DESCRIPTION_100' | transloco"
        >
        </avn-empty-data-message>
      </div>
    </ng-template>
  </avn-dialog-content>

  <avn-dialog-footer>
    <avn-button
      (actioned)="onUpdateBoard()"
      [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.SAVE_10' | transloco"
      avnTheme="primary"
      [disabled]="boardFormGroup.invalid || boardFormGroup.pristine"
    ></avn-button>
    <avn-button (actioned)="onCancel()" [label]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.CANCEL_10' | transloco"></avn-button>
  </avn-dialog-footer>
</avn-dialog-layout>
