import { AuthFacade } from '@amp/auth';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, ReplaySubject, share } from 'rxjs';
import { map } from 'rxjs/operators';


import * as GlobalAction from './global.action';
import { IGlobalState } from './global.reducer';
import { globalQuery } from './global.selectors';
import { dataOnceReady } from '@activia/ngx-components';

@Injectable({ providedIn: 'root' })
export class GlobalFacade {
  /** Connection status */
  // Whether if user is connected to the network
  isUserOnline$ = this.store.pipe(select(globalQuery.getIsUserOnline));

  /** store observables */
  userLanguage$ = this.store.pipe(select(globalQuery.getUserLanguage));
  defaultTimeZone$ = this.store.pipe(select(globalQuery.getDefaultTimeZone));
  /** List of device groups available to the role of the current user */
  deviceGroups$ = dataOnceReady(this.store.pipe(select(globalQuery.getDeviceGroups)), this.store.pipe(select(globalQuery.getDeviceGroupsDataState)));
  searchResultSize$ = this.store.pipe(select(globalQuery.getSearchResultSize));
  engineVersion$ = this.store.pipe(select(globalQuery.getEngineVersion));
  customerName$ = this.store.pipe(select(globalQuery.getCustomerName));

  /** All Devices device group. Only available when user has access to this device group */
  allDevicesDeviceGroup$ = this.deviceGroups$.pipe(
    map((deviceGroups) => this._findAllDevicesDeviceGroup(deviceGroups))
  );

  /**
   * Device group at app level (used in dashboard, user settings, side nav) to be used as default.
   * If user has chosen one in preference, this one would be the default. Otherwise if user is authorized
   * to All Devices device group, this one would be the default. Otherwise the default is the first device
   * group found in user' scopes.
   */
  defaultDeviceGroup$ = combineLatest([
    // Default device group saved in user's preference
    dataOnceReady(this.store.pipe(select(globalQuery.getDefaultDeviceGroup)), this.store.pipe(select(globalQuery.getDefaultDeviceGroupDataState))),
    this.authFacade.authenticatedUserScopes$,
    this.deviceGroups$,
  ]).pipe(
    map(([defaultDeviceGroup, scopes, availableDeviceGroups]) => {
      let deviceGroup = defaultDeviceGroup;

      // If user does not choose a default device group in preference yet, choose one as default
      if (!deviceGroup) {
        if (scopes && scopes.length > 0) {
          // If user is authorized to see "All Devices" device group, use Fthis one
          const authorizeAllDeviceGroups = scopes.filter((scope) => !!scope.authorizeAllDeviceGroups).length > 0;
          if (authorizeAllDeviceGroups) {
            deviceGroup = this._findAllDevicesDeviceGroup(availableDeviceGroups) || availableDeviceGroups[0];
          } else {
            // User has restricted scopes, take the first device group in the list
            deviceGroup = scopes.flatMap((s) => s.deviceGroups || [])[0];
          }
        }
      }
      return deviceGroup;
    }),
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: true,
      resetOnComplete: false,
      resetOnError: false,
    }),
  );

  constructor(private store: Store<IGlobalState>, private authFacade: AuthFacade) {}

  /** Updates the user language */
  public updateUserLanguage(lang: string) {
    this.store.dispatch(GlobalAction.UserLanguageUpdate({ language: lang }));
  }

  /** Update user preferences back into store */
  public updateUserPreferences(preferences: Array<{ key: string; value: any; field: string }>) {
    this.store.dispatch(GlobalAction.UserPreferencesUpdate({ preferences }));
  }

  private _findAllDevicesDeviceGroup(deviceGroups) {
    // Backend has validation to prevent name duplication. 'All Devices' is already being used in the system.
    // No other device groups can be called the same name. Hence searching by name case-sensitively should be ok.
    return deviceGroups.find((dg) => dg.name === 'All Devices');
  }
}
