import { ApiTokenService } from '@amp/auth';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { from, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RetrieveSessionGuard implements CanActivate {
  constructor(private apiTokenService: ApiTokenService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    // retreive the session if passed and initialize it in the token service.
    // added for sso scenarios
    if (route.queryParams['sessionid']) {
      const { sessionid, ...queryParams } = route.queryParams;

      this.apiTokenService.setApiToken(sessionid);

      const urlWithoutQueryParams = state.url.split('?')[0];

      // Remove sessionId from queryParams
      return from(
        this.router.navigate([urlWithoutQueryParams], {
          queryParams, // Keep all queryParams except sessionid
          queryParamsHandling: '', // Replace current parameters with new parameters
          replaceUrl: true, // Replace in history instead of adding new value
        })
      );
    }

    return of(true);
  }
}
