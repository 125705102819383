<avn-page>
  <avn-main>
    <avn-master-detail [resizable]="true" [activeDetailView]="activeDetailView" [detailWidth]="'50%'" [masterMinWidthPx]="700" [detailMinWidthPx]="350" [transitionPlayBackwards]="index === 0">
      <!-- Board org path tree + Preview -->
      <div avnMasterView class="board-orgpath-section board-orgpath-edit-definition">
        <ng-container *avnAsyncData="boardOrgPathDef$ | async; state: boardOrgPathState$ | async; loaderType: 'loader'">
          <!-- Tree -->
          <h4>{{ 'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.BOARD_DEFINITION_40' | transloco }}</h4>

          <div class="board-orgpath-tree" avnScroll>
            <amp-board-orgpath-tree class="board-orgpath-tree-root" *ngIf="boardOrgPathDef$ | async as orgPathNode" [id]="orgPathNode.id"></amp-board-orgpath-tree>
          </div>

          <!-- Empty message if empty tree -->
          <avn-empty-data-message
            *ngIf="(boardOrgPathDef$ | async) === undefined"
            class="empty-constraint"
            icon="action:add_circle_outline"
            [text]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NO_EXISTING_ORGPATH_TEXT_100' | transloco"
            [subText]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.NO_EXISTING_ORGPATH_SUBTEXT_100' | transloco"
            (click)="addFirstNode()"
          ></avn-empty-data-message>

          <!-- Footer -->
          <div class="board-orgpath-footer">
            <div class="board-orgpath-buttons-footer">
              <avn-button
                *ngIf="activeDetailView?.id !== '3'"
                class="board-orgpath-save-button"
                [label]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.SHOW_PREVIEW_50' | transloco"
                avnTheme="primary"
                [disabled]="hasErrors$ | async"
                (actioned)="openPreview(true)"
              ></avn-button>

              <avn-button
                *ngIf="activeDetailView?.id === '3'"
                class="board-orgpath-save-button"
                [label]="'siteManagementScope.SITE_MANAGEMENT.GLOBAL.ORGANIZATIONAL_PATH.BOARD_ORGPATH_EDITOR.HIDE_PREVIEW_50' | transloco"
                avnTheme="primary"
                (actioned)="openPreview(false)"
              ></avn-button>

              <avn-button class="board-orgpath-save-button" [disabled]="isSaved$ | async" [label]="'button.save' | transloco" avnTheme="primary" (actioned)="onSaveBoardOrgPathDef()"></avn-button>
            </div>
          </div>
        </ng-container>
      </div>

      <!------ Side menus ------>
      <!-- Current node (2 side menu for transition between 2 nodes) -->
      <ng-template [avnDetailView]="'0'">
        <amp-board-orgpath-node-editor
          [node]="index === 0 ? activeNode : previousNode"
          class="board-orgpath-node-editor"
          (openEditTagPanel)="openEditTagPanel()"
          (openAddTagPanel)="openAddTagPanel()"
        ></amp-board-orgpath-node-editor>
      </ng-template>
      <ng-template [avnDetailView]="'1'">
        <amp-board-orgpath-node-editor
          [node]="index === 1 ? activeNode : previousNode"
          class="board-orgpath-node-editor"
          (openEditTagPanel)="openEditTagPanel()"
          (openAddTagPanel)="openAddTagPanel()"
        ></amp-board-orgpath-node-editor>
      </ng-template>

      <!-- Edit Tag  -->
      <ng-template [avnDetailView]="'2'">
        <ng-container *ngIf="boardTagsDefinition$ | async as tagDefinition">
          <amp-tag-key-detail
            [tag]="{ key: activeNode.tag, level: engineTagLevel.BOARD, description: tagDefinition[activeNode.tag] }"
            [getInvalidTagsFn]="getInvalidTag"
            (editTag)="editTag($event)"
            (closed)="goToCurrentNode()"
          ></amp-tag-key-detail>
        </ng-container>
      </ng-template>

      <!-- Preview Org path -->
      <ng-template [avnDetailView]="'3'">
        <avn-detail-layout>
          <avn-detail-header [title]="'siteManagementScope.SITE_MANAGEMENT.BOARD_EDITOR.GLOBAL.PREVIEW_20' | transloco" (closed)="openPreview(false)"> </avn-detail-header>
          <avn-detail-content avnScroll *ngIf="boardOrgPathDef$ | async as boardOrgPathNode">
            <amp-board-org-path-form
              class="board-orgpath-preview-content"
              *ngIf="boardTagsDefinition$ | async as tagDefinition"
              [tagDefinition]="tagDefinition | applyFunction: getSchemaFromTagDesc"
              [nodeDefinition]="boardOrgPathNode"
              avnScroll
            >
            </amp-board-org-path-form>
          </avn-detail-content>
        </avn-detail-layout>
      </ng-template>
    </avn-master-detail>
  </avn-main>
</avn-page>
