import { Pipe, PipeTransform } from '@angular/core';

/**
 * Capitalize and remove whiteSpace
 */

@Pipe({
  name: 'removeWhitespacesCapitalize',
  pure: false,
})
export class RemoveWhitespacesCapitalizePipe implements PipeTransform {
  public transform(value: string) {
    if (!value || value.trim().length === 0) {
      return value;
    }
    return value
      .split(' ')
      .map((word) => word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase())
      .join('');
  }
}
