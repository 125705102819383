<avn-select
  [placeholder]="'tagOperation.TAG_OPERATION.TAG_TYPES.SELECT_TYPE_PLACEHOLDER_50' | transloco"
  [label]="label | transloco"
  [options]="options"
  [optionsTemplate]="optionTemplate"
  [optionHeight]="'auto'"
  [value]="selectedType"
  (change)="selectedTypeChanged.emit($event.value)"
></avn-select>

<ng-template #optionTemplate let-option="option">
  <div style="display: flex; flex-direction: column; width: 100%; white-space: normal; margin: var(--space-sm)">
    <div style="font-size: medium; font-weight: bolder">{{ 'tagOperation.TAG_OPERATION.TAG_TYPES.' + option.value.toUpperCase() + '_NAME_20' | transloco }}</div>
    <div style="font-size: smaller; opacity: 0.7; font-weight: lighter">{{ 'tagOperation.TAG_OPERATION.TAG_TYPES.' + option?.value.toUpperCase() + '_DESC_100' | transloco }}</div>
  </div>
</ng-template>
