import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { IOptionData, ThemeType } from '@activia/ngx-components';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, debounceTime, distinctUntilChanged, Observable, of, startWith, Subject, takeUntil } from 'rxjs';
import { IJsonConstraint, JsonSchemaPropertyType } from '@activia/json-schema-forms';
import { TagKeyDescDTO } from '@activia/cm-api';
import { AssetTagKeyDescDTO } from '@amp/cms-api';
import { IEngineTagKey } from '../../model/engine-tag-key.interface';
import { ableToAssignMultiValue, adjustTagKeyCustomRequirement, ConstraintTypeEnum, ConstraintTypes, SCHEMA_TAG_SUFFIX } from '../../utils/schema-helper';
import { EngineTagLevel } from '../../model/operation-scope.interface';
import { ITagValue } from '../../model/tag-value.interface';
import { isEmpty, omit } from 'lodash';
import { filter, map, tap } from 'rxjs/operators';

export enum TagKeyCreationEnum {
  tagKey = 'tagKey',
  title = 'title',
  description = 'description',
  examples = 'examples',
  default = 'default',
  dynamic = 'dynamic',
  multivalues = 'multivalues',
  level = 'level',
}

@Component({
  selector: 'amp-create-tag-key',
  templateUrl: './create-tag-key.component.html',
  styleUrls: ['./create-tag-key.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTagKeyComponent implements OnInit, OnDestroy {
  @Output() actioned: EventEmitter<IEngineTagKey> = new EventEmitter<IEngineTagKey>();

  @Input() keyList$: Observable<IEngineTagKey[]>;

  /** Specifies which level the tag should be created. If not specified, UI shows a dropdown to select this option */
  @Input() set tagLevel(tagLevel: EngineTagLevel) {
    this.tagForm.get(this.tagKeyCreationEnum.level).setValue(tagLevel);
  }

  get tagLevel(): EngineTagLevel {
    return this.tagForm.get(this.tagKeyCreationEnum.level)?.value;
  }

  /** (Optional) Specifies if tag allow multiple value. If not specified, UI shows a switch to select this option */
  @Input() allowMultipleValue: boolean;

  /** Enable Level Change, so the user can select one of the level dropdown options, default is true */
  @Input() enableLevelChange = true;

  /** List of all tag keys that already exist */
  existingKeys$: Observable<string[]>;
  tagKeyCreationEnum: typeof TagKeyCreationEnum = TagKeyCreationEnum;
  themeType = ThemeType;
  /** Form for 1st step */
  tagForm: FormGroup = this.initialState();
  schemaTypeOptions: ConstraintTypeEnum[] = ConstraintTypes;
  tagSchemaValueSub: Subject<{ valid: boolean; schema: IJsonConstraint }> = new Subject();
  /** Choose schema type to create */
  schemaType: ConstraintTypeEnum;
  /** schema values from tag-constraint-component */
  tagSchemaObj: { valid: boolean; schema: IJsonConstraint };
  ableToAssignMultiValue = ableToAssignMultiValue;
  tagLevelOptions: IOptionData<void>[] = [
    { label: this._translate.translate('tagOperation.TAG_OPERATION.TAG_LIST.LEVEL.SITE'), value: EngineTagLevel.SITE },
    { label: this._translate.translate('tagOperation.TAG_OPERATION.TAG_LIST.LEVEL.BOARD'), value: EngineTagLevel.BOARD },
    {
      label: this._translate.translate('tagOperation.TAG_OPERATION.TAG_LIST.LEVEL.SCREEN'),
      value: EngineTagLevel.SCREEN,
    },
  ];

  private componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder, private _translate: TranslocoService, private _cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.tagSchemaValueSub
      .pipe(
        debounceTime(100),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((change) => {
        const forceFail = this.schemaType === 'specific' && change.schema.enum?.length === 0;
        this.tagSchemaObj = { valid: forceFail ? false : change.valid, schema: change.schema };
        this._cdr.detectChanges();
      });

    if (this.keyList$) {
      this.existingKeys$ = this.getExistingKeys$();
      this.updateTagKeyValidator();
    }

    this._cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public onTagLevelChanged(option: IOptionData<void>): void {
    this.tagForm.get(this.tagKeyCreationEnum.level).setValue(option.value);
  }

  public onMultiValuesChanged(): void {
    this.tagSchemaValueSub.next(this.tagSchemaObj);
  }

  public onSchemaTypeChanged(schemaType: ConstraintTypeEnum): void {
    this.schemaType = schemaType;
    this.addOrRemoveControls(schemaType);
    this._cdr.detectChanges();
  }

  public onSchemaEdited(change: { valid: boolean; schema: IJsonConstraint }): void {
    this.tagSchemaValueSub.next(change);
  }

  /** Create tag key object based on user input */
  public createTagKey(): void {
    const tagKey = this.getTagKey(this.tagForm, this.tagSchemaObj?.schema);
    this.actioned.emit(tagKey);
  }

  public getTagKey(tagForm: FormGroup, schema: IJsonConstraint): IEngineTagKey {
    return {
      key: adjustTagKeyCustomRequirement(tagForm.get(this.tagKeyCreationEnum.tagKey).value, this.schemaType),
      level: tagForm.get(this.tagKeyCreationEnum.level).value,
      description: this.getDescription(tagForm.value, schema),
    } as IEngineTagKey;
  }

  public hasChanges({ tagValue, schemaType }): boolean {
    const obj1 = omit(tagValue, [this.tagKeyCreationEnum.level]);
    const obj2 = omit(this.initialState().value, [this.tagKeyCreationEnum.level]);
    return JSON.stringify(obj1) !== JSON.stringify(obj2) || !!schemaType;
  }

  public getTagTypeLabel(schemaType): string {
    return this._translate.translate(`tagOperation.TAG_OPERATION.TAG_TYPES.${schemaType.toUpperCase()}_NAME_20`);
  }

  public convertToTagValue({ tagValue, schema }): ITagValue {
    return {
      key: tagValue[this.tagKeyCreationEnum.tagKey],
      propertyType: tagValue[this.tagKeyCreationEnum.level] as EngineTagLevel,
      values: [tagValue[this.tagKeyCreationEnum.default]],
      keyDescription: this.getDescription(tagValue, schema),
    } as ITagValue;
  }

  private getDescription(tagValue, schema: IJsonConstraint): TagKeyDescDTO | AssetTagKeyDescDTO {
    const description = {
      dynamic: tagValue[this.tagKeyCreationEnum.dynamic],
      multivalues: this.allowMultipleValue ? tagValue[this.tagKeyCreationEnum.multivalues] : false,
      schema: {
        type: 'string',
        title: tagValue[this.tagKeyCreationEnum.title],
        examples: isEmpty(tagValue[this.tagKeyCreationEnum.examples]?.toString()) ? null : tagValue[this.tagKeyCreationEnum.examples],
        default: isEmpty(tagValue[this.tagKeyCreationEnum.default]) ? null : tagValue[this.tagKeyCreationEnum.default],
        description: isEmpty(tagValue[this.tagKeyCreationEnum.description]) ? null : tagValue[this.tagKeyCreationEnum.description],
        ...schema,
      },
    };
    if (description.schema.type === 'object' && description.schema.default && typeof description.schema.default === 'string') {
      description.schema.default = JSON.parse(description.schema.default);
    }
    return description;
  }

  private initialState(): FormGroup {
    return this.fb.group({
      [this.tagKeyCreationEnum.tagKey]: ['', { validators: [Validators.required, Validators.pattern(/^[A-Za-z0-9]*$/)] }],
      [this.tagKeyCreationEnum.title]: ['', Validators.required],
      [this.tagKeyCreationEnum.examples]: this.fb.array(['']),
      [this.tagKeyCreationEnum.default]: [''],
      [this.tagKeyCreationEnum.description]: [''],
      [this.tagKeyCreationEnum.dynamic]: [false],
      [this.tagKeyCreationEnum.multivalues]: [false],
      [this.tagKeyCreationEnum.level]: ['', Validators.required],
    });
  }

  private updateTagKeyValidator(): void {
    this.existingKeys$
      .pipe(
        filter((existingKeys: string[]) => existingKeys.length > 0),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap((existingKeys: string[]) => {
          this.tagForm.get(this.tagKeyCreationEnum.tagKey).setAsyncValidators([this.isKeyUniqueValidator(this.tagLevel, existingKeys)]);
          this.tagForm.get(this.tagKeyCreationEnum.tagKey).updateValueAndValidity();
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe();
  }

  private isKeyUniqueValidator(tagLevel: EngineTagLevel, existingKeys: string[]): AsyncValidatorFn {
    if (!tagLevel) {
      return;
    }
    return (control: AbstractControl): Observable<ValidationErrors> =>
      of(existingKeys).pipe(
        map((keys: string[]) => keys.some((a) => a === control.value)),
        map((result: boolean) => (result ? { keyAlreadyExist: true } : null))
      );
  }

  private addOrRemoveControls(schemaType: ConstraintTypeEnum) {
    if (schemaType === 'schema') {
      this.tagForm.removeControl(TagKeyCreationEnum.default);
      this.tagForm.removeControl(TagKeyCreationEnum.examples);
    } else {
      if (!this.tagForm.controls[TagKeyCreationEnum.default]) {
        this.tagForm.addControl(TagKeyCreationEnum.default, new FormControl(''));
      }
      if (!this.tagForm.controls[TagKeyCreationEnum.examples]) {
        this.tagForm.addControl(TagKeyCreationEnum.examples, this.fb.array(['']));
      }
    }
  }

  private getExistingKeys$(): Observable<string[]> {
    return combineLatest([this.keyList$, this.tagForm.get(this.tagKeyCreationEnum.level).valueChanges.pipe(startWith(this.tagLevel))]).pipe(
      map(([tagList, level]: [IEngineTagKey[], EngineTagLevel]) => {
        const filteredTagsByLevel = tagList.filter((tag) => tag.level === level);
        return filteredTagsByLevel.map((tag) => this.removeTagKeyCustomRequirement(tag.key, (tag?.description?.schema as any)?.type));
      })
    );
  }

  private removeTagKeyCustomRequirement = (keyName: string, type: JsonSchemaPropertyType) =>
    !!keyName && (type === 'object' || type === 'array') ? keyName.slice(0, keyName.lastIndexOf(SCHEMA_TAG_SUFFIX)) : keyName;
}
