import { SiteDTO, SitesService } from '@activia/cm-api';
import { Observable, of, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import { CountryService, GeoTimezoneService, GoogleMapsService } from '@activia/geo';
import { ISiteGeoResponse, validateCsvSiteGeoLocation$ } from './site-sync-geo.utils';
import { updateSite$ } from '../../../utils/site.utils';
import {
  CustomError,
  RequestChain,
  RequestChainFunction,
} from '../request-chain';
import { take } from 'rxjs/operators';
import { validateRequiredValuesFilled } from './site-sync-validation.utils';

export const updateSiteRequestChain = (
  site$: Observable<SiteDTO>,
  googleMapsService: GoogleMapsService,
  geoTimezoneService: GeoTimezoneService,
  countryService: CountryService,
  siteService: SitesService,
  isMissingRequiredValueFixedForSite$: Observable<boolean>,
  isGeoFixedForSite$: Observable<boolean>
): RequestChain => {
  // 1 verify all required fields are provided
  const validateRequiredValuesStep$: RequestChainFunction = () => isMissingRequiredValueFixedForSite$.pipe(
      take(1),
      withLatestFrom(site$),
      switchMap(([isMissingRequiredValueFixedForSite, site]) => {
        if (isMissingRequiredValueFixedForSite) {
          return of({ dataValidationResponse: { response: 'ok' } });
        }
        const invalidFields = validateRequiredValuesFilled(site);
        return invalidFields.length === 0 ?
          of({ dataValidationResponse: { response: 'ok' } }) :
          throwError(new CustomError(`missing required value`, 'missing-required-value', { dataValidationResponse: { response: 'warning', fields: invalidFields } }));
      })
    );

  // 2 check geo
  // @ts-ignore
  const validateGeoStep$: RequestChainFunction = () =>
    isGeoFixedForSite$.pipe(
      take(1),
      withLatestFrom(site$),
      switchMap(([isGeoFixed, site]) =>
        isGeoFixed
          ? of({ response: 'ok' } as ISiteGeoResponse)
          : validateCsvSiteGeoLocation$(site, googleMapsService).pipe(
              tap((geoResponse) => {
                if (geoResponse.response === 'error' || geoResponse.response === 'warning') {
                  // stop the request sequence
                  const errorCode = geoResponse.status;
                  const errorData = { geoResponse };
                  throw new CustomError(`geo ${geoResponse.response}`, errorCode, errorData);
                }
              })
            )
      )
    );

  // 3 update site
  const updateSiteStep$: RequestChainFunction = () => site$.pipe(
      take(1),
      switchMap((site) => updateSite$(geoTimezoneService, countryService, siteService, site, true))
    );

  const sequenceRequest = new RequestChain();
  sequenceRequest.registerRequest({
    id: 'validate-required-values',
    request$: validateRequiredValuesStep$,
  });
  sequenceRequest.registerRequest({
    id: 'validate-geo',
    request$: validateGeoStep$,
  });
  sequenceRequest.registerRequest({ id: 'update-site', request$: updateSiteStep$ });
  return sequenceRequest;
};
