import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IOptionData } from '@activia/ngx-components';
import { ConstraintTypeEnum } from '@amp/tag-operation';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'amp-tag-type-selector',
  templateUrl: './tag-type-selector.component.html',
  styleUrls: ['./tag-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagTypeSelectorComponent {

  @Input() label = 'tagOperation.TAG_OPERATION.TAG_DETAIL.TYPE_20';

  options: IOptionData<string>[];
  @Input() set schemaTypeOptions(types: ConstraintTypeEnum[]) {
    this.options = (types || []).map((value) => {
      const i18nKey = `tagOperation.TAG_OPERATION.TAG_TYPES.${value.toUpperCase()}_NAME_20`;
      return { label: this._translate.translate(i18nKey), value };
    });
  }

  @Input() selectedType: ConstraintTypeEnum;

  @Output() selectedTypeChanged: EventEmitter<ConstraintTypeEnum> = new EventEmitter<ConstraintTypeEnum>();

  constructor(private _translate: TranslocoService) {}
}
