import { Injectable } from '@angular/core';
import {
  ApiRequestState,
  AsyncDataService,
  AsyncDataState,
  combineDataStates,
  dataOnceReady
} from '@activia/ngx-components';
import { ISiteMonitoringData } from '../../../model/site-monitoring-data.interface';
import { SiteMonitoredValue } from '../../../model/site-monitored-value.enum';
import { SiteMonitoringFacade } from '../../../store/site-monitoring.facade';
import { toInternalSiteMonitoredValues } from '../../../utils/site-monitored-values.utils';
import { combineLatest, Observable, of, ReplaySubject, share } from 'rxjs';
import { ISiteMonitoringKeyMetricViewerData } from '../../../model/site-monitoring-key-metric-viewer-data.interface';
import { map, switchMap } from 'rxjs/operators';
import { getVisibleKeyMetrics } from '../../../utils/key-metrics.utils';
import {
  preloadBoardOrgPathDefinition,
  preloadKeyMetrics,
  preloadProfile
} from '../../../utils/site-monitoring-guards.utils';
import { AlarmEventLevel } from '@amp/devices';
import { MonitoringAlarmEventDTO } from '@activia/cm-api';
import { IBoardWithOrgPath } from '../../../model/board-with-orgpath.interface';
import { IDeviceInfo } from '../../site-monitoring-detail/store/site-monitoring-detail.model';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class KeyMetricsViewerService {

  siteId: number;

  private keyMetrics$: Observable<ISiteMonitoringKeyMetricViewerData[]>;
  private keyMetricsDataState$: Observable<AsyncDataState>;

  constructor(
    private _asyncDataService: AsyncDataService,
    private _siteMonitoringFacade: SiteMonitoringFacade,
    store: Store,
  ) {
    // In DMB the profile and key metric selections are not preloaded in guard. Need to load them if not yet done.
    preloadProfile(this._siteMonitoringFacade).subscribe();
    preloadKeyMetrics(this._siteMonitoringFacade).subscribe();
    preloadBoardOrgPathDefinition(store).subscribe();
  }

  getSiteKeyMetrics(siteId: number): { keyMetrics$: Observable<ISiteMonitoringKeyMetricViewerData[]>; keyMetricsDataState$: Observable<AsyncDataState> } {
    if (siteId !== this.siteId) {
      this._fetchSiteKeyMetrics(siteId);
    }
    return { keyMetrics$: this.keyMetrics$, keyMetricsDataState$: this.keyMetricsDataState$ };
  }

  private _fetchSiteKeyMetrics(siteId: number) {
    this.siteId = siteId;

    const monitoringValuesRequestState = new ApiRequestState();
    const alarmsRequestState = new ApiRequestState();

    // Monitoring values required by selected key metrics
    const monitoringValues$ = this._siteMonitoringFacade.keyMetricsDataSource$.pipe(
      map((keyMetricsDataSource) => keyMetricsDataSource.monitoringValues),
      switchMap((monitoringValuesToFetch: SiteMonitoredValue[]) => {
        const request$ = !siteId || (monitoringValuesToFetch || []).length === 0 ?
          of({}) : this._siteMonitoringFacade.fetchOrganizationSummary(siteId, monitoringValuesToFetch);
        const monitoredData$ =
          this._asyncDataService.doRequestWithState$<ISiteMonitoringData>(
            request$,
            monitoringValuesRequestState,
            null,
            {}
          );

        return monitoredData$.pipe(
          map((resp) => toInternalSiteMonitoredValues(resp)),
        );
      })
    );

    // If enclosure status key metric is selected, the data of this key metric comes from alarms,
    // there is no matching monitoring value
    const alarmsData$ = this._siteMonitoringFacade.keyMetricsDataSource$.pipe(
      map((keyMetricsDataSource) => keyMetricsDataSource.alarmTypes),
      switchMap((alarmTypes) => {
        const request$ = !siteId || (alarmTypes || []).length === 0 ? of({} as any) : this._siteMonitoringFacade.fetchSiteAlarms(siteId, AlarmEventLevel.Debug, true, null, true);
        const alarmEvents$ = this._asyncDataService.doRequestWithState$<{ alarms: MonitoringAlarmEventDTO[]; boards: IBoardWithOrgPath[]; devices: Partial<IDeviceInfo>[] }>(
          request$,
          alarmsRequestState,
          null,
          { alarms: [], boards: [], devices: [] }
        );
        return alarmEvents$;
      })
    );

    this.keyMetrics$ = combineLatest([
      dataOnceReady(this._siteMonitoringFacade.keyMetrics$, this._siteMonitoringFacade.keyMetricsDataState$),
      monitoringValues$,
      alarmsData$,
    ]).pipe(
      map(([keyMetrics, monitoredData, alarmsData]) => getVisibleKeyMetrics(keyMetrics, monitoredData, alarmsData?.alarms, alarmsData?.boards, alarmsData?.devices)),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );

    this.keyMetricsDataState$ = combineLatest([
      this._siteMonitoringFacade.keyMetricsDataState$,
      monitoringValuesRequestState.dataState$,
      alarmsRequestState.dataState$,
    ]).pipe(
      map((dataStates) => combineDataStates(dataStates)),
      share({
        connector: () => new ReplaySubject(1),
        resetOnRefCountZero: true,
        resetOnComplete: false,
        resetOnError: false,
      })
    );
  };
}
