import { AsyncData, LoadingState } from '@activia/ngx-components';
import { Action, createReducer, on } from '@ngrx/store';
import * as DeviceAction from './devices.actions';
import * as GlobalAction from '@amp/global';
import { AppPreferenceKeys, UserPreferenceKeys } from '@amp/global';
import { IMonitoringListDeviceCount, IMonitoringSharedList } from '../model/monitoring-list.interface';
import { v4 as uuid } from 'uuid';
import { ExportDevicesTaskStatus, IExportDevicesTask } from '../model/export-devices-task.interface';

export interface IDevicesState {
  /** Shared Lists */
  sharedLists: AsyncData<IMonitoringSharedList>;

  /** Shared lists device counts */
  sharedListsDeviceCount: IMonitoringListDeviceCount[];

  /** Tasks of exports currently being processed */
  exportTasks: IExportDevicesTask[];
}

export const initialDevicesState: IDevicesState = {
  sharedLists: {
    data: {
      new: [],
      dataset: [],
    },
    state: LoadingState.INIT,
  },
  sharedListsDeviceCount: [],
  exportTasks: [],
};

const reducer = createReducer<IDevicesState>(
  initialDevicesState,

  on(DeviceAction.DevicesFetchSharedLists, (state, _) => ({
    ...state,
    sharedLists: {
      ...state.sharedLists,
      state: LoadingState.LOADING,
    },
  })),

  on(DeviceAction.DevicesFetchSharedListsSuccess, (state, action) => ({
    ...state,
    sharedLists: {
      data: {
        ...state.sharedLists.data,
        new: action.data.new,
        dataset: action.data.dataset,
      },
      state: LoadingState.LOADED,
    },
  })),

  on(DeviceAction.DevicesFetchSharedListsFail, (state, action) => ({
    ...state,
    sharedLists: {
      ...state.sharedLists,
      state: {
        errorMsg: action.errorMessage,
      },
    },
  })),

  on(DeviceAction.DevicesUpdateSharedListCount, (state, action) => {
    const existingCount = state.sharedListsDeviceCount.find((sl) => sl.listId === action.sharedListId);
    const previousCount = action.resetCountHistory || !existingCount ? null : existingCount.count;
    return {
      ...state,
      sharedListsDeviceCount: [
        ...state.sharedListsDeviceCount.filter((sharedListCount) => sharedListCount.listId !== action.sharedListId),
        { listId: action.sharedListId, state: LoadingState.LOADING, count: null, previousCount },
      ],
    };
  }),

  on(DeviceAction.DevicesUpdateSharedListCountSuccess, (state, action) => ({
    ...state,
    sharedListsDeviceCount: [
      ...state.sharedListsDeviceCount.filter((sharedListCount) => sharedListCount.listId !== action.sharedListId),
      { ...state.sharedListsDeviceCount.find((sl) => sl.listId === action.sharedListId), state: LoadingState.LOADED, count: action.count },
    ],
  })),

  on(DeviceAction.DevicesUpdateSharedListCountFail, (state, action) => ({
    ...state,
    sharedListsDeviceCount: [
      ...state.sharedListsDeviceCount.filter((sharedListCount) => sharedListCount.listId !== action.sharedListId),
      { ...state.sharedListsDeviceCount.find((ul) => ul.listId === action.sharedListId), state: { errorMsg: action.errorMessage }, count: null },
    ],
  })),

  on(GlobalAction.AppPreferencesSingleKeyUpdateSuccess, (state, action) => {
    if (action.key === AppPreferenceKeys.MONITORING_SHARED_LISTS) {
      const newState = {
        ...state,
        sharedLists: {
          ...state.sharedLists,
          state: LoadingState.LOADED,
          data: {
            ...state.sharedLists.data,
            dataset: action.data,
          },
        },
      };

      return newState;
    }
    return state;
  }),

  on(GlobalAction.AppPreferencesSingleKeyUpdateFail, (state, action) => {
    if (action.key === AppPreferenceKeys.MONITORING_SHARED_LISTS) {
      return {
        ...state,
        sharedLists: {
          ...state.sharedLists,
          state: { errorMsg: action.errorMessage },
        },
      };
    }
    return state;
  }),

  on(GlobalAction.UserPreferencesSingleKeyUpdateSuccess, (state, action) => {
    if (action.key === UserPreferenceKeys.MONITORING_SHARED_LISTS) {
      const id = action.custom;
      return {
        ...state,
        sharedLists: {
          ...state.sharedLists,
          data: {
            ...state.sharedLists.data,
            new: [...state.sharedLists.data.new.filter((list) => list !== id)],
          },
        },
      };
    }

    return state;
  }),

  on(DeviceAction.DevicesAddExportTask, (state, { task }) => ({
      ...state,
      exportTasks: [
        ...state.exportTasks,
        { ...task, id: uuid(), status: 'PROCESSING' as ExportDevicesTaskStatus },
      ],
    })),

  on(DeviceAction.DevicesAddExportTaskSuccess, (state, { task }) => {
    const taskIdx = state.exportTasks.findIndex((t) => t.id === task.id);

    return {
      ...state,
      exportTasks: [...state.exportTasks.slice(0, taskIdx), task, ...state.exportTasks.slice(taskIdx + 1)],
    };
  }),

  on(DeviceAction.DevicesAddExportTaskFail, (state, { task }) => {
    const taskIdx = state.exportTasks.findIndex((t) => t.id === task.id);

    return {
      ...state,
      exportTasks: [...state.exportTasks.slice(0, taskIdx), task, ...state.exportTasks.slice(taskIdx + 1)],
    };
  }),

  on(DeviceAction.DevicesClearExportTasks, (state) => ({
    ...state,
    exportTasks: [],
  })),

);

export const devicesReducer = (state: IDevicesState | undefined, action: Action): IDevicesState => reducer(state, action);
