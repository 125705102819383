import { IBadgeSize, ThemeType } from '@activia/ngx-components';
import { DisplayType } from '@amp/column-picker';
import { ContentStatus, ContentStatusThemes } from '../../model/content-status.enum';
import { ContentStatusErrorIds } from '../../model/content-status-error-ids.enum';
import { DevicePropertiesStatus, DevicePropertiesStatusThemeMap } from '../../model/device-properties-status.enum';
import { DeviceProperty } from '../../model/device-properties.enum';
import { HealthErrorIds } from '../../model/health-error-ids.enum';
import { HealthStatusCode } from '../../model/health-status.enum';
import { MonitoredValue } from '../../model/monitored-value.enum';
import { OperationalState } from '../../model/operational-state.enum';
import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'amp-device-field-templates',
  templateUrl: './device-field-templates.component.html',
  styleUrls: ['./device-field-templates.component.scss'],
})
export class DeviceFieldTemplatesComponent implements OnInit, OnChanges {
  /** Device field name **/
  @Input() field: any;

  /** Device field value **/
  @Input() value: any;

  /** Device field display type **/
  @Input() displayType: DisplayType;

  @Input() badgeSize: IBadgeSize = IBadgeSize.SMALL;

  /** @ignore to use in template **/
  DeviceProperty = DeviceProperty;

  /** @ignore to use in template **/
  MonitoredValue = MonitoredValue;

  /** @ignore to use in template **/
  HealthStatusCode = HealthStatusCode;

  /** @ignore to use in template **/
  HealthErrorIds = HealthErrorIds;

  /** @ignore to use in template **/
  OperationalState = OperationalState;

  /** @ignore to use in template **/
  ContentStatus = ContentStatus;

  /** @ignore to use in template **/
  ContentStatusThemes = ContentStatusThemes;

  /** @ignore to use in template **/
  ContentStatusErrorIds = ContentStatusErrorIds;

  /** @ignore to use in template **/
  DevicePropertiesStatus = DevicePropertiesStatus;
  DevicePropertiesStatusTheme = DevicePropertiesStatusThemeMap;

  /** @ignore to use in template **/
  DisplayType = DisplayType;

  /** @ignore to use in template **/
  ThemeType = ThemeType;

  get fields(): Array<string> {
    return !!this.value && typeof this.value === 'object' ? Object.keys(this.value) : [];
  }

  constructor(private _elementRef: ElementRef<any>, private _renderer2: Renderer2, public translate: TranslocoService) {}

  ngOnInit(): void {
    // default back to raw display if the display type is no more valid (in case changes were made to display types)
    if (this.displayType && !Object.values(DisplayType).some((displayType) => displayType === this.displayType)) {
      this.displayType = DisplayType.Raw;
    }
  }

  ngOnChanges({ displayType }: SimpleChanges): void {
    if (displayType) {
      // for some displayTypes we want the template to span 100% of its parent
      const isFullWidth = [DisplayType.PercentChart].includes(displayType.currentValue);
      if (isFullWidth) {
        this._renderer2.setStyle(this._elementRef.nativeElement, 'width', '100%');
      } else {
        this._renderer2.removeStyle(this._elementRef.nativeElement, 'width');
      }
    }
  }

  isPlayerState(field: string): boolean {
    const regex = new RegExp(`^${MonitoredValue.PlayerState}[_0-9]*$`);
    return !!field.match(regex);
  }

  /** percent value validation */
  public percentValidation(value: number): number {
    return value >= 0 ? (value > 100 ? 100 : value) : 0;
  }
}
