<div class="page-container" [formGroup]="tagForm">
  <div class="form-section" avnScroll>
    <div class="form-content">
      <ng-container *ngIf="enableLevelChange">
        <avn-select
          class="level"
          [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.TAG_LEVEL_TITLE_50' | transloco"
          [options]="tagLevelOptions"
          [value]="tagLevel"
          (change)="onTagLevelChanged($event)"
          [placeholder]="'tagOperation.TAG_OPERATION.TAG_TYPES.SELECT_LEVEL_PLACEHOLDER_50' | transloco"
        ></avn-select>
      </ng-container>
      <amp-tag-type-selector
        [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.TYPE_20' | transloco"
        [schemaTypeOptions]="schemaTypeOptions"
        [selectedType]="schemaType"
        (selectedTypeChanged)="onSchemaTypeChanged($event)"
      >
      </amp-tag-type-selector>
      <div class="tag-key-creation-switch-options" *ngIf="schemaType && schemaType !== 'schema'">
        <div class="dynamic" *ngIf="enableLevelChange">
          <avn-switch labelPosition="after" formControlName="dynamic" [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.DYNAMIC_20' | transloco" [avnTheme]="themeType.PRIMARY"></avn-switch>
          <avn-icon [icon]="'action:info'" [fontSize]="18" [avnTooltip]="'tagOperation.TAG_OPERATION.TAG_DETAIL.DYNAMIC_TOOLTIP_200' | transloco"></avn-icon>
        </div>
        <ng-container *ngIf="allowMultipleValue">
          <avn-switch
            *ngIf="schemaType | applyFunction: ableToAssignMultiValue"
            labelPosition="after"
            formControlName="multivalues"
            class="tag-detail-switch-multiple-values"
            [label]="'tagOperation.TAG_OPERATION.TAG_DETAIL.ALLOW_MULTIPLE_VALUES_60' | transloco"
            [avnTheme]="themeType.PRIMARY"
            (statusChanged)="onMultiValuesChanged()"
          ></avn-switch>
        </ng-container>
      </div>
      <avn-json-schema-constraint *ngIf="schemaType" [type]="schemaType" (changed)="onSchemaEdited($event)" [schema]="tagSchemaObj?.schema"> </avn-json-schema-constraint>
    </div>
  </div>
  <div class="form-section" avnScroll>
    <div *ngIf="tagForm" class="form-content">
      <!-- Tag Key -->
      <div>
        <avn-form-field>
          <avn-input [formControlName]="tagKeyCreationEnum.tagKey" [label]="'tagOperation.TAG_OPERATION.TAG_SCHEMA.FORM.LABEL_60' | transloco" [avnTheme]="themeType?.PRIMARY" [debounceTime]="300">
          </avn-input>
          <avn-form-field-error key="required" [message]="'tagOperation.TAG_OPERATION.TAG_SCHEMA.FORM.KEY_REQUIRED_60' | transloco"> </avn-form-field-error>
          <avn-form-field-error key="keyAlreadyExist" [message]="'tagOperation.TAG_OPERATION.TAG_SCHEMA.FORM.KEY_EXIST_60' | transloco"></avn-form-field-error>
          <avn-form-field-error key="pattern" [message]="'tagOperation.TAG_OPERATION.TAG_SCHEMA.FORM.INVALID_KEY_60' | transloco"> </avn-form-field-error>
        </avn-form-field>
      </div>
      <!-- Tag description -->
      <avn-json-schema-description [schemaFormGroup]="tagForm" [jsonSchema]="tagSchemaObj?.schema" [hideDefault]="tagForm.controls.multivalues.value"></avn-json-schema-description>
      <!-- Buttons -->
      <div class="tag-key-creation-buttons">
        <avn-button
          class="tag-key-creation-button"
          [disabled]="!tagForm.valid || !schemaType"
          (actioned)="createTagKey()"
          [label]="'button.create' | transloco"
          [avnTheme]="themeType.PRIMARY"
        ></avn-button>
      </div>
    </div>
  </div>
  <div class="form-section" *ngIf="tagForm && schemaType?.toString() !== 'schema'">
    <ng-container *ngIf="{ tagValue: tagForm.value, schemaType: this.schemaType } | applyFunction: hasChanges:[]:this; else noTagDefinition">
      <div class="form-content">
        <div>
          <span>{{ 'tagOperation.TAG_OPERATION.TAG_DETAIL.PREVIEW_TITLE_50' | transloco }}</span>
        </div>
        <div class="preview-content" *ngIf="schemaType && tagSchemaObj?.schema">
          <div class="schema-container">
            <label class="schema-type">{{ schemaType | applyFunction: getTagTypeLabel:[]:this }}</label>

            <amp-tag-value-item [tag]="{ tagValue: tagForm.value, schema: tagSchemaObj?.schema } | applyFunction: convertToTagValue:[]:this" [isEdited]="false" [isDeletable]="false">
            </amp-tag-value-item>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noTagDefinition>
      <div class="empty-tag">
        <label>{{ 'tagOperation.TAG_OPERATION.TAG_DETAIL.EMPTY_TAG_DEFINITION_100' | transloco }}</label>
      </div>
    </ng-template>
  </div>
</div>
