import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input } from '@angular/core';
import { IExperienceTemplate } from '../../../models/experience-template.interface';

@Component({
  selector: 'amp-experience-template-player-selection',
  templateUrl: './experience-template-player-selection.component.html',
  styleUrls: ['./experience-template-player-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExperienceTemplatePlayerSelectionComponent implements OnInit {
  @Input() experienceTemplate: IExperienceTemplate;

  @Input() siteName: string;

  @Output() actioned: EventEmitter<'provision' | 'connect' | 'close'> = new EventEmitter();

  /** i18n key pluralized when necessary */
  i18nMessage = {
    '=0': 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.INTRODUCTION_MESSAGE_100.SINGULAR',
    '=1': 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.INTRODUCTION_MESSAGE_100.SINGULAR',
    other: 'siteManagementScope.SITE_MANAGEMENT.EXPERIENCE_TEMPLATE.SELECTION_MODAL.INTRODUCTION_MESSAGE_100.PLURAL',
  };

  constructor() {}

  ngOnInit(): void {}

  selectOption(value: 'provision' | 'connect' | 'close') {
    this.actioned.emit(value);
  }
}
