import { ModalService } from '@activia/ngx-components';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { isObservable, Observable, of, switchMap } from 'rxjs';
import { SaveBeforeExitDialogComponent } from '../components/save-before-exit-dialog/save-before-exit-dialog.component';

export interface ISaveBeforeExit {
  /** Tell if the component holds unsaved changes */
  hasUnsavedChanges: () => boolean | Observable<boolean>;

  /** Function to save changes. Return true if saved successfully, false otherwise */
  save: () => Observable<boolean>;
}

@Injectable({ providedIn: 'root' })
export class SaveBeforeExitGuard implements CanDeactivate<ISaveBeforeExit> {
  constructor(private _modalService: ModalService) {}

  canDeactivate(component: ISaveBeforeExit): Observable<boolean> {
    const hasUnsavedChanges = component.hasUnsavedChanges();
    const hasUnsavedChanges$ = isObservable(hasUnsavedChanges) ? hasUnsavedChanges : of(hasUnsavedChanges);

    return hasUnsavedChanges$.pipe(
      switchMap((unsavedChanges) => {
        if (!unsavedChanges) {
          return of(true);
        } else {
          // Open the modal asking user to confirm save or discard
          const modalRef = this._modalService.open<SaveBeforeExitDialogComponent, void>(SaveBeforeExitDialogComponent, { closeOnBackdropClick: false });
          return modalRef.componentInstance.actioned.pipe(
            switchMap((result) => {
              switch (result) {
                case 'save':
                  return component.save();
                case 'discard':
                  if ('hasChanges' in component) {
                    component['hasChanges'] = false;
                  }
                  return of(true);
                case 'cancel':
                  return of(false);
              }
            })
          );
        }
      })
    );
  }
}
