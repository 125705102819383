import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { SiteListService } from '../services/site-list.service';

@Injectable()
export class PreloadRefreshSitesGuard implements CanActivate {
  constructor(private _siteListService: SiteListService) {}

  canActivate(): Observable<boolean> {
    return this._siteListService.startTimer().pipe(mapTo(true));
  }
}
